import {
  Box,
  Button,
  Card,
  CardFooter,
  CircularProgress,
  Container,
  Flex,
  useToast
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useExpertLetterDrafts } from "../../../../hooks/useExpertLetterDrafts";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SpecialHeading } from "../../PanelLayout";
import { SectionWrapper } from "../../../common/SectionWrapper";

export const EditExpertLetterDraft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { id, expertId, visaType } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const editorRef = useRef(null);
  const [value, setValue] = useState(null);

  const { updateDraft, getSingleDraft, isLoadingSingleDraft, singleDraft } =
    useExpertLetterDrafts(uid);

  const getDraftById = useCallback(
    (id: string) => {
      getSingleDraft(id);
    },
    [getSingleDraft]
  );

  useEffect(() => {
    if (expertId) {
      getDraftById(expertId);
    }
  }, [expertId]);

  useEffect(() => {
    setValue(singleDraft?.value.text);
  }, [singleDraft?.value]);

  const handleSubmit = async (
    expertLetterId: string,
    text: string,
    html: string
  ) => {
    const payload = { text, html };
    if (expertLetterId) await updateDraft(expertLetterId, payload);
    toast({
      title: "Success",
      description: "Draft updated successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom-right"
    });
  };

  return (
    <SectionWrapper
      withIndividualEditBox
      bg="white"
      backTargetPage="Drafts"
      onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
    >
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <SpecialHeading title="Draft expert letter" backNav />
        {isLoadingSingleDraft ? (
          <CircularProgress isIndeterminate />
        ) : (
          <div className="w-full">
            {singleDraft?.value ? (
              <RichTextEditor
                text={singleDraft.value.html || singleDraft.value.text}
                saveText={(html, text) => handleSubmit(expertId!, text, html)}
                shouldPreserveWhitespace
              />
            ) : (
              <Box fontSize="18px">
                To generate a first draft, click the Edit icon on the card.
              </Box>
            )}
          </div>
        )}
      </Flex>
    </SectionWrapper>
  );
};
