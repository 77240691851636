import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack
} from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { autoFillQuestionnaireWithDocuments } from "../../../api/OpenAIApi";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { AiGenerateIcon } from "../../common/Icons";
import { eb2Docs } from "../../../testTableData";
import { filterActiveDocuments } from "../../../helpers/helpers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const AIQuestionnaireModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { id: uid } = useParams();
  const groupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const allDocuments: DataDocs[] = filterActiveDocuments(groupedDocuments);
  const [selectedDocuments, setSelectedDocuments] = useState<DataDocs[]>([]);
  const [autoFilling, setAutoFilling] = useState(false);
  const questionnaireDocRef = doc(db, DATABASE.QUESTIONNAIRES, uid!);

  const getUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const document = await getDoc(questionnaireDocRef);
      const data = document.data();
      if (data && "usedDocuments" in data) {
        // setUsedDocuments(data.usedDocuments as string[]);
        const usedDocumentsIds = data.usedDocuments as string[];
        const getUsedDocuments = allDocuments.filter((doc) =>
          usedDocumentsIds.includes(doc.id!)
        );
        setSelectedDocuments(getUsedDocuments);
      }
    }
  };
  const updateUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const selectedDocumentsIds = selectedDocuments.map((doc) => doc.id);
      await updateDoc(questionnaireDocRef, {
        usedDocuments: selectedDocumentsIds
      });
    }
  };

  useEffect(() => {
    getUsedDocuments();
  }, []);

  const handleAutoFill = async () => {
    setAutoFilling(true);
    autoFillQuestionnaireWithDocuments(selectedDocuments, uid!);
    onClose();

    setAutoFilling(false);

    await updateUsedDocuments();
    await getUsedDocuments();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textColor="text.color">
          Generate Questionnaires Using AI
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Warning Section */}
          <Box bg="errors.red2" borderRadius="md" p={4} mb={4}>
            <div className="flex justify-center items-center gap-4">
              <WarningIcon fill="white" color="red" boxSize={5} />
              <div className="flex flex-col">
                <Text fontWeight="bold" color="text.color" mb={2}>
                  Questionnaire Regeneration
                </Text>
                <Text color="text.color" fontWeight={400}>
                  Selecting a new document will overwrite current answers and
                  remove any prior edits to the questionnaire.
                </Text>
              </div>
            </div>
          </Box>

          {/* Dropdown Section */}
          <Text mb={2}>Choose a document to pre-fill the questionnaire</Text>
          <Flex w="100%">
            <DropdownGeneric
              list={allDocuments}
              mergingField="id"
              searchField="autoTitle"
              groupBy="super_class"
              checkedItems={selectedDocuments.map((doc) => doc.id!)}
              getSelectedItems={(selectedItems) => {
                setSelectedDocuments(selectedItems);
              }}
              singleSelect={false}
              withCheck
              withArrow
              withFilter
              withView
              isDisabled={autoFilling}
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          {/* Buttons */}
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={selectedDocuments.length === 0}
            isLoading={autoFilling}
            leftIcon={<Box as={AiGenerateIcon} boxSize={6} fill="white" />}
            onClick={(e) => handleAutoFill()}
          >
            Re-Generate with AI
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AIQuestionnaireModal;
