import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import _ from "lodash";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useFileActions from "../../../hooks/useFileActions";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, visaFromPath, VISAVALUE } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
// eslint-disable-next-line import/no-cycle
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import UploadList from "./UploadList";
import { TableRef } from "../../../types/document";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { getDocumentTypesByCategory } from "../../../data/tables-data";
import { mapVisaTypeToPath } from "../../../helpers/helpers";
import { SelectedItem } from "../../../types/dropdown.types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  storagePath: string;
  collectionPath: string;
  visaDocumentType: VisaDocumentType;
}

const UploadByCategoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  storagePath,
  collectionPath,
  visaDocumentType
}) => {
  const { id, visaType } = useParams();
  const { handleFileActions } = useFileActions();
  const documentTypes = useSelector(documentSelectors.documentTypes);

  const reduceToSelectedItems = (
    data: any,
    visaType: string
  ): SelectedItem[] => {
    if (!data) return [];
    const visaTypeForMap = visaFromPath[visaType];

    const result: SelectedItem[] = [];
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (data[key] && data[key][visaTypeForMap]) {
        Object.keys(data[key][visaTypeForMap]).forEach((category) => {
          result.push({
            super_class: key,
            category
          });
        });
      }
    });

    return result;
  };

  const [selectedCategory, setSelectedCategory] = useState<
    SelectedItem | undefined
  >(undefined);

  const list: SelectedItem[] = reduceToSelectedItems(documentTypes, visaType!);

  function getDocumentTypesBySelectedItem(item: SelectedItem | undefined) {
    if (!item || !documentTypes || !visaType) return [];
    const selectedSuperClass = item.super_class;
    const mappedVisa = visaFromPath[visaType!];

    return documentTypes[selectedSuperClass][mappedVisa!][item.category];
  }

  const types = useMemo(
    () => getDocumentTypesBySelectedItem(selectedCategory),
    [selectedCategory]
  );

  const [selectedType, setSelectedType] = useState<string | undefined>(
    undefined
  );

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedCategory(undefined);
      setSelectedType(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedType(undefined);
    }
  }, [selectedCategory]);

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const isFilesListEmpty = files.length === 0;
  const isCategoryEmpty = selectedCategory === undefined;

  const handleUploadFiles = async () => {
    if (!storagePath || !collectionPath) return;
    handleFileActions({
      files,
      path: storagePath,
      collectionPath,
      uid: id!,
      category: selectedCategory?.category,
      type: selectedType,
      super_class: selectedCategory?.super_class
      // visaDocumentType: undefined!
    });
    onClose();
  };

  const onCloseComplete = () => {
    console.log("Modal closed, resetting files.");
    setFiles([]); // Reset files only when the modal is fully closed
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={onCloseComplete}>
      <ModalOverlay />
      <ModalContent minWidth={files.length > 0 ? "66vw" : "46vw"}>
        <ModalHeader>Upload by category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex columnGap={8} justifyContent="start" alignItems="start">
            <UploadList files={files} onRemove={handleRemoveFile} />
            <Box>
              <VStack spacing={4} align="stretch" mr={8}>
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <Box w="fit-content">
                    <DropdownGeneric
                      withArrow
                      singleSelect
                      label="Select Category"
                      getSelectedItem={(item) => {
                        if (item !== undefined) setSelectedCategory(item);
                      }}
                      groupBy="super_class"
                      mergingField="super_class"
                      searchField="category"
                      list={list}
                    />
                  </Box>
                </FormControl>
                {selectedCategory && types.length > 0 && (
                  <FormControl>
                    <FormLabel>Document Type (Optional)</FormLabel>
                    <Dropdown
                      list={types}
                      title="Select Type"
                      selectedValue={selectedType}
                      onItemSelect={handleTypeChange}
                    />
                  </FormControl>
                )}

                <Box w="40vw">
                  <UploadFilesPlaceholder
                    styleIndex={1}
                    title=""
                    subTitle=""
                    onUpload={(fileList: FileList | null) => {
                      if (fileList) {
                        const newFiles = Array.from(fileList);
                        setFiles((prev) => [...prev, ...newFiles]);
                      }
                    }}
                  />
                </Box>
              </VStack>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="neutralStateButton" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isCategoryEmpty || isFilesListEmpty}
            variant="primaryFilled"
            ml={3}
            onClick={handleUploadFiles}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadByCategoryModal;
