/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Spinner,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
  useToast
} from "@chakra-ui/react";
import { forwardRef, useRef } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FaSave } from "react-icons/fa";
import ReactQuill from "react-quill";

type Props = {
  quillRef: ReactQuill | null;
  isDownloading: boolean;
  handleDownload: (format: string) => void;
  editorId?: string;
};
export const RichTextToolbar = forwardRef<HTMLDivElement, Props>(
  ({ quillRef, isDownloading, handleDownload, editorId = "" }, ref) => {
    const toast = useToast();

    return (
      <Box
        id={`toolbar-${editorId}`}
        display="flex"
        flexWrap="wrap"
        bg="white"
        position="relative"
        zIndex="20"
        className="toolbar"
        borderTopRadius="md"
        ref={ref}
      >
        {/* we will need this to highlight the selectedText
      in the future */}
        {/* <div className="ql-formats">
        <select className="ql-color">
          <option value="red" />
          <option value="green" />
          <option value="blue" />
          <option value="orange" />
          <option value="violet" />
          <option value="#d0d1d2" />
        </select>
        <select className="ql-background">
          <option value="yellow" />
          <option value="lightgreen" />
          <option value="lightblue" />
          <option value="lightpink" />
          <option value="lightgray" />
          <option value="#000000" />
        </select>
      </div> */}
        <span className="ql-formats">
          <select className="ql-header" defaultValue="0">
            <option value="1">Heading 1</option>
            <option value="2">Heading 2</option>
            <option value="3">Heading 3</option>
            <option value="0">Normal</option>
          </select>{" "}
        </span>
        <span className="ql-formats">
          <button className="ql-bold" type="button" aria-label="test" />
          <button className="ql-italic" type="button" aria-label="test" />
          <button className="ql-underline" type="button" aria-label="test" />
          <button className="ql-link" type="button" aria-label="test" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-clean" />
        </span>
        <span className="ql-formats ml-auto">
          <button className="ql-copy" type="button" aria-label="test">
            <CopyIcon
              onClick={(e) => {
                const text = quillRef?.getEditor().getText();
                if (text) navigator.clipboard.writeText(text);
                if (!toast.isActive("copy"))
                  toast({
                    title: "Summary copied to clipboard! ",
                    id: "copy"
                  });
              }}
            />
          </button>
          {isDownloading && <Spinner size="md" color="primary.darkBlue" />}
          {!isDownloading && (
            <Menu variant="toolbar">
              <MenuButton
                as={Button}
                leftIcon={<FaSave />}
                className="!w-auto !text-black"
                bg="transparent"
              >
                Export and Save
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={
                    <Box w={4} h={4}>
                      <FileIcon extension="docx" {...defaultStyles.docx} />
                    </Box>
                  }
                  onClick={() => {
                    handleDownload("docx");
                  }}
                >
                  DOCX
                </MenuItem>
                <MenuItem
                  icon={
                    <Box w={4} h={4}>
                      <FileIcon extension="pdf" {...defaultStyles.pdf} />
                    </Box>
                  }
                  onClick={() => {
                    handleDownload("pdf");
                  }}
                >
                  PDF
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </span>
      </Box>
    );
  }
);

RichTextToolbar.displayName = "RichTextToolbar";
