import React, { PropsWithChildren } from "react";
import {
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button,
  Divider
} from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";

const data = [
  {
    title: "Extractions from standard documents",
    content:
      "Extractions include structured information and summaries from the uploaded documents. You may edit content as needed."
  },
  {
    title: "Resume Summary",
    content:
      "The information from the summary will be used throughout the case preparation process. Please review the content for accuracy and edit as needed."
  },
  {
    title: "Extractions from evidence documents",
    content:
      "Extractions include structured information and summaries from the uploaded documents. You may review and edit the descriptions and change document categories."
  },
  {
    title: "Edit Main Document",
    content:
      "AI generated titles will be used in the draft Exhibit List and Packet tabs. You can change the document category, document type, make edits to the title, or add a description to provide more context."
  },
  {
    title: "Supporting Documents",
    content: (
      <Box>
        <Text mb={4}>
          You can bundle related documents together by adding them as supporting
          documents. Bundling documents will result in one combined summary.
        </Text>
      </Box>
    )
  },
  {
    title: "Case Strategy",
    content:
      "Here you can provide additional information about the strategy of the case. This information will be used to build the case."
  },
  {
    title: "Case Research",
    content:
      "Case research includes additional research topics based on the individual’s area of expertise and uploaded documents. Review the answer to the area of expertise in the Questionnaire tab."
  },
  {
    title: "Extractions from case research",
    content:
      "Summaries from case research will be used to supplement the case. You may review and edit the content here."
  },
  {
    title: "Expert Letters",
    content:
      "Add experts to draft recommendation or testimonial letters. To get started, upload a resume if available, and answer any questions at the intake step."
  },
  {
    title: "Exhibit list",
    content:
      "The exhibit list follows the order in the Documents tab. Arrange the documents and review the AI-generated titles in the “Evidence Documents” tab before generating the Exhibit List."
  },
  {
    title: "Legal Brief",
    content:
      "The legal brief / support letter combines all the information in Evidence Document Summaries, the Questionnaires, Research, and Finalized Expert Letters."
  },
  {
    title: "Signed expert letters",
    content:
      "Once finalized, the signed expert letters should be uploaded here."
  },
  {
    title: "Final packet",
    content:
      "The final packet includes the legal brief, exhibit list, and the applicant’s documents separated by exhibit tabs. If you add additional documents, regenerate the Exhibit List before generating the Packet."
  },
  {
    title: "Standard documents",
    content: (
      <Box>
        <Text fontWeight={600} mb={4}>
          Standard documents include:
        </Text>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
          <UnorderedList spacing={2}>
            <ListItem>Resume</ListItem>
            <ListItem>Passport</ListItem>
            <ListItem>Visa</ListItem>
            <ListItem>I-94</ListItem>
            <ListItem>Status documents</ListItem>
            <ListItem>Educational documents</ListItem>
          </UnorderedList>
          <UnorderedList spacing={2}>
            <ListItem>Academic evaluation</ListItem>
          </UnorderedList>
        </Box>
      </Box>
    )
  },
  {
    title: "Evidence documents",
    content: (
      <Box>
        <Text fontWeight={600} mb={4}>
          Evidence documents include:
        </Text>
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
          <UnorderedList spacing={2}>
            <ListItem>Publications</ListItem>
            <ListItem>Awards or prizes</ListItem>
            <ListItem>Articles</ListItem>
            <ListItem>Art exhibits</ListItem>
            <ListItem>Proof of high salary</ListItem>
            <ListItem>Evidence of leading role</ListItem>
            <ListItem>Position job description</ListItem>
          </UnorderedList>
          <UnorderedList spacing={2}>
            <ListItem>Citation records</ListItem>
            <ListItem>Entrepreneur documents</ListItem>
            <ListItem>
              Employment contract, offer letter, itinerary, or agent agreement
            </ListItem>
            <ListItem>Patents, trademarks, certificates</ListItem>
            <ListItem>Information about the area of expertise</ListItem>
          </UnorderedList>
        </Box>
      </Box>
    )
  },
  {
    title: "Case Documents",
    content: (
      <div>
        <Text>
          Upload all case documents here, including standard and evidence
          documents, expert letters, and internal questionnaires. You can let
          CaseBlinkAI automatically sort and organize the evidence for you or
          upload by category to manually organize your documents.
        </Text>
      </div>
    )
  },
  {
    title: "Documents",
    content: (
      <div className="flex flex-col">
        <Box>
          <Text fontWeight={600} mb={4}>
            Evidence documents include:
          </Text>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <UnorderedList spacing={2}>
              <ListItem>Publications</ListItem>
              <ListItem>Awards or prizes</ListItem>
              <ListItem>Articles</ListItem>
              <ListItem>Art exhibits</ListItem>
              <ListItem>Proof of high salary</ListItem>
              <ListItem>Evidence of leading role</ListItem>
              <ListItem>Position job description</ListItem>
            </UnorderedList>
            <UnorderedList spacing={2}>
              <ListItem>Citation records</ListItem>
              <ListItem>Entrepreneur documents</ListItem>
              <ListItem>
                Employment contract, offer letter, itinerary, or agent agreement
              </ListItem>
              <ListItem>Patents, trademarks, certificates</ListItem>
              <ListItem>Information about the area of expertise</ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Divider my={4} />
        <Box>
          <Text fontWeight={600} mb={4}>
            Standard documents include:
          </Text>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <UnorderedList spacing={2}>
              <ListItem>Resume</ListItem>
              <ListItem>Passport</ListItem>
              <ListItem>Visa</ListItem>
              <ListItem>I-94</ListItem>
              <ListItem>Status documents</ListItem>
              <ListItem>Educational documents</ListItem>
            </UnorderedList>
            <UnorderedList spacing={2}>
              <ListItem>Academic evaluation</ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </div>
    )
  }
] as const;

type TitleType = (typeof data)[number]["title"];
interface DocumentsInfoModalProps extends PropsWithChildren {
  title: TitleType;
}

export const DocumentsInfoModal = ({
  title,
  children
}: DocumentsInfoModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Find the content based on the provided title
  const item = data.find((d) => d.title === title);

  return (
    <>
      <IconButton
        variant="filledIconButton"
        onClick={onOpen}
        aria-label="View information"
        icon={<MdInfoOutline size={20} />}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor="#00004D">{item?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{item?.content || children}</ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
