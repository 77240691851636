import {
  CheckCircleIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon
} from "@chakra-ui/icons";
import {
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
  useDisclosure,
  Switch,
  Spacer,
  Icon,
  Box
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineLibraryBooks,
  MdOutlineUnfoldMore,
  MdUnfoldLess
} from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import { openFilePreview } from "../../helpers/helpers";
import {
  ensureSuperclassFormat,
  getShortString
} from "../../helpers/string_helpers";
import useDocuments from "../../hooks/useDocuments";
import { documentSelectors } from "../../redux/documents/selectors";
import { VisaDocumentType } from "../../redux/documents/types";
import { DataDocs } from "../../types/tables-data";
import EditMainDocModal from "../individualTabs/individualDocuments/EditMainDocModal";
import { RowDragHandleCell } from "./DraggableRow";
import { GroupedDiv, SubGroupDiv } from "./RowHelpers";
import { EXTRACTIONSTATES } from "../../redux/extraction-jobs/extractionJobsSlice";
import {
  DocumentRowNewBadge,
  DocumentRowStatus,
  NewBadge
} from "./DocumentRowStatus";
import { CriteriaIcon } from "./Icons";

export const DocumentsTable = () => {
  const { visaType: visaTypeInParam, id: uid } = useParams();

  const isLoadingDocuments = useSelector(documentSelectors.isLoadingDocuments);
  const superGroupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const flattenedSuperGroupedDocuments = superGroupedDocuments.flatMap((sup) =>
    sup.groups.flatMap((group) => group.subrows)
  );

  const [isAiToggled, setIsAiToggled] = useState(true);

  const { groupedDocuments, updateCurrentOrder } = useDocuments(
    [
      {
        path: `/documents/${uid}/docs`,
        documentType: VisaDocumentType.Standard,
        super_class: "Standard"
      },
      {
        path: `/documents/${uid}/signed_expert_letters`,
        documentType: VisaDocumentType.SignedExpertLetters,
        super_class: "Letters"
      },
      {
        path: `/documents/${uid}/evidence_docs`,
        documentType: VisaDocumentType.Evidence,
        super_class: "Evidence"
      }
    ],
    false
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {})
  );

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const [isDeleting, setIsDeleting] = useState(false);

  const [documentToDelete, setDocumentToDelete] = useState<
    DataDocs | undefined
  >();

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();

  const handleEditClick = (document: DataDocs) => {
    setDocumentToEdit({ ...document });
  };

  const updateDocumentNewField = (document: DataDocs | undefined) => {
    if (
      document &&
      document.docRef &&
      document.isNew &&
      document.super_class !== "Processing"
    ) {
      const docRef = doc(db, document.docRef);
      updateDoc(docRef, { isNew: false });
    }
  };

  const handleDeleteClick = (document: DataDocs) => {
    setDocumentToDelete({ ...document });
    updateDocumentNewField(document);
  };

  const debouncedUpdate = useCallback(
    _.debounce((docs) => updateCurrentOrder(docs), 1000),
    []
  );

  const documentsHasCriteria = (documents: DataDocs[]) => {
    return documents.some((doc) => {
      const generalClasses = Object.values(doc.general_class || {});
      return generalClasses.includes("CRITERIA");
    });
  };

  useEffect(() => {
    if (groupedDocuments && groupedDocuments.length > 0) {
      debouncedUpdate(groupedDocuments);
    }
    return () => {
      debouncedUpdate.cancel();
    };
  }, [groupedDocuments]);

  // expanded logic
  // useEffect(() => {
  //   if (
  //     !isLoadingDocuments &&
  //     groupedDocuments &&
  //     groupedDocuments.length > 0
  //   ) {
  //     const expandedMap: Record<string, boolean> = {};
  //     groupedDocuments.forEach((group) => {
  //       if (!expanded[group.type]) expandedMap[group.type] = false;
  //     });
  //     setExpanded((prevExpanded) => {
  //       return { ...prevExpanded, ...expandedMap };
  //     });
  //   }
  // }, [groupedDocuments]);

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  useEffect(() => {
    if (documentToDelete) {
      onOpen();
    }
  }, [documentToDelete, onOpen]);

  useEffect(() => {
    updateCurrentOrder(superGroupedDocuments);
  }, [flattenedSuperGroupedDocuments.length]);

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = Boolean(e.target.checked);

    setIsAiToggled(checked);
  };
  const toggleGroupExpansion = (superClass: string, groupType: string) => {
    const updatedDocuments = superGroupedDocuments.map((superGroup) => {
      if (superGroup.super_class === superClass) {
        return {
          ...superGroup,
          groups: superGroup.groups.map((group) => {
            if (group.type === groupType) {
              return { ...group, expanded: !group.expanded };
            }
            return group;
          })
        };
      }
      return superGroup;
    });

    updateCurrentOrder(updatedDocuments);
  };
  const toggleSuperGroupExpansion = (superClass: string, fold: boolean) => {
    const updatedDocuments = superGroupedDocuments.map((superGroup) => {
      if (superGroup.super_class === superClass) {
        return {
          ...superGroup,
          groups: superGroup.groups.map((group) => ({
            ...group,
            expanded: fold
          }))
        };
      }
      return superGroup;
    });

    updateCurrentOrder(updatedDocuments);
  };

  return (
    <div>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        id="group"
        onDragEnd={(e) => {
          const { active, over } = e;
          if (active && over) {
            const isActiveCategory = active.id.toString().includes("cat");
            const isOverCategory = over.id.toString().includes("cat");
            const activeSuperClass = active?.data?.current?.metadata;
            const activeRowId = active?.data?.current?.id;

            // implement isNew Logic when moving the row

            if (activeRowId) {
              const document = flattenedSuperGroupedDocuments.find(
                (doc) => doc.id === activeRowId
              );
              updateDocumentNewField(document);
            }

            // shuffling categories case

            if (isActiveCategory && isOverCategory && activeSuperClass) {
              const splitActive = active.id.toString().split(":");
              const splitOver = over.id.toString().split(":");

              const activeCategory = splitActive[1];
              const overCategory = splitOver[1];

              const superGroupIndex = superGroupedDocuments?.findIndex(
                (el) => el.super_class === activeSuperClass
              );
              if (
                superGroupIndex !== undefined &&
                superGroupIndex !== -1 &&
                superGroupedDocuments
              ) {
                const superGroup = superGroupedDocuments[superGroupIndex];
                const activeCategoryIndex = superGroup?.groups.findIndex(
                  (group) => group.type === activeCategory
                );
                const overCategoryIndex = superGroup?.groups.findIndex(
                  (group) => group.type === overCategory
                );

                const newSuperGroupedDocuments = [...superGroupedDocuments];
                const newSuperGroup = {
                  ...newSuperGroupedDocuments[superGroupIndex]
                };

                newSuperGroup.groups = arrayMove(
                  [...newSuperGroup.groups],
                  activeCategoryIndex,
                  overCategoryIndex
                );
                newSuperGroupedDocuments[superGroupIndex] = newSuperGroup;
                updateCurrentOrder(newSuperGroupedDocuments!);
              }
            }

            // shuffling documents in same category case
            else if (!isActiveCategory && !isOverCategory) {
              const {
                metadata: { group, superClass }
              }: any = active.data.current;

              const superGroupIndex = superGroupedDocuments?.findIndex(
                (el) => el.super_class === superClass
              );

              if (
                superGroupedDocuments &&
                superGroupIndex !== undefined &&
                superGroupIndex !== -1
              ) {
                const newSuperGroupedDocuments = [...superGroupedDocuments];
                const superGroup = {
                  ...newSuperGroupedDocuments[superGroupIndex]
                };

                const categoryIndex = superGroup.groups.findIndex(
                  (cat) => group === cat.type
                );

                if (categoryIndex !== -1) {
                  const group = { ...superGroup.groups[categoryIndex] };

                  const subRows = [...group.subrows];
                  const activeIndex = subRows.findIndex(
                    (subrow) => subrow.id === active.id
                  );
                  const overIndex = subRows.findIndex(
                    (subrow) => subrow.id === over.id
                  );

                  if (activeIndex !== -1 && overIndex !== -1) {
                    const newSubRows = arrayMove(
                      subRows,
                      activeIndex,
                      overIndex
                    );

                    group.subrows = newSubRows;

                    const newGroups = [...superGroup.groups];
                    newGroups[categoryIndex] = group;

                    superGroup.groups = newGroups;

                    newSuperGroupedDocuments[superGroupIndex] = superGroup;

                    updateCurrentOrder(newSuperGroupedDocuments);
                  }
                }
              }
            }
          }
        }}
      >
        {/* debugging */}
        {/* <pre>{flattenedSuperGroupedDocuments.length}</pre>
        <pre>{JSON.stringify(flattenedSuperGroupedDocuments, null, 2)}</pre> */}

        <div className="flex">
          <Table>
            <Thead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Tr>
                <Th>Exhibit</Th>
                <Th>
                  {isAiToggled ? "ai-generated title" : "Document Title"}{" "}
                  <Switch
                    mx={1}
                    isChecked={isAiToggled}
                    onChange={handleToggleChange}
                  />
                </Th>
                <Th textAlign="right">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* Sortable groups */}
              {isLoadingDocuments || !superGroupedDocuments ? (
                <Tr key="placeholder">
                  <Td />
                  <Td>
                    <CircularProgress
                      isIndeterminate
                      display="flex"
                      justifyContent="center"
                      mx="auto"
                    />
                  </Td>
                </Tr>
              ) : (
                superGroupedDocuments?.map(
                  (superGroup) =>
                    superGroup.groups.length !== 0 && (
                      <>
                        <Tr
                          className="!bg-[#4A6ABB] text-white   "
                          key={superGroup.super_class}
                        >
                          <Td colSpan={2} className="uppercase" pt={2} pb={2}>
                            <span className="inline-flex items-center gap-1 ">
                              {superGroup.groups.some(
                                (group) => group.expanded
                              ) ? (
                                <IconButton
                                  p={0}
                                  bg="transparent"
                                  title="Collapse All"
                                  icon={<MdUnfoldLess size={24} />}
                                  aria-label="unfold"
                                  onClick={() =>
                                    toggleSuperGroupExpansion(
                                      superGroup.super_class,
                                      false
                                    )
                                  }
                                />
                              ) : (
                                <IconButton
                                  bg="transparent"
                                  title="Expand All"
                                  icon={<MdOutlineUnfoldMore size={24} />}
                                  aria-label="fold "
                                  onClick={() =>
                                    toggleSuperGroupExpansion(
                                      superGroup.super_class,
                                      true
                                    )
                                  }
                                />
                              )}
                              {ensureSuperclassFormat(superGroup.super_class)}
                            </span>
                          </Td>
                          <Td
                            pt={2}
                            pb={2}
                            className="cursor-pointer"
                            onClick={() => {
                              if (superGroup.super_class !== "Processing") {
                                const path = `/individual/${uid}/${visaTypeInParam}/documents/${superGroup.super_class}/extracted-information`;
                                navigate(path);
                              }
                            }}
                          >
                            <Spacer />
                            {superGroup.super_class !== "Processing" && (
                              <div className="flex flex-shrink gap-1 items-center justify-end ">
                                <Button
                                  bg="transparent"
                                  border="1px solid white"
                                  _hover={{
                                    bg: "rgba(255, 255, 255, 0.25)"
                                  }}
                                  px={6}
                                  leftIcon={<MdOutlineLibraryBooks />}
                                >
                                  View Summaries
                                </Button>
                              </div>
                            )}
                          </Td>
                        </Tr>

                        <SortableContext
                          key={`sortable-${superGroup.super_class}`}
                          items={superGroup.groups.map((group) => ({
                            id: `cat:${group.type}:${superGroup.super_class}`
                          }))}
                        >
                          {/* categories */}
                          {superGroup.groups.map(
                            (group, index) =>
                              group.subrows.length > 0 && (
                                <>
                                  <GroupedDiv
                                    id={`cat:${group.type}:${superGroup.super_class}`}
                                    key={`cat:${group.type}:${superGroup.super_class}`}
                                    metadata={superGroup.super_class}
                                  >
                                    <Td colSpan={3}>
                                      <div className="flex items-center">
                                        <RowDragHandleCell
                                          rowId={`cat:${group.type}:${superGroup.super_class}`}
                                        />
                                        <div className="flex items-center gap-2 w-fit">
                                          <IconButton
                                            variant="filledIconButton"
                                            onClick={() => {
                                              toggleGroupExpansion(
                                                superGroup.super_class,
                                                group.type
                                              );
                                            }}
                                            aria-label=""
                                            icon={
                                              group.expanded ? (
                                                <MdOutlineExpandLess />
                                              ) : (
                                                <MdOutlineExpandMore />
                                              )
                                            }
                                          />

                                          <div>
                                            {!group.type ||
                                            group.type === "" ? (
                                              <Skeleton w={20} h={8} />
                                            ) : (
                                              <div className="flex gap-2 justify-center items-center">
                                                <Text
                                                  border="1px"
                                                  borderColor="#01004D"
                                                  px={2}
                                                  py={1}
                                                  borderRadius={6}
                                                  color="#01004D"
                                                >
                                                  {documentsHasCriteria(
                                                    group.subrows
                                                  ) && (
                                                    <Box
                                                      as={CriteriaIcon}
                                                      boxSize={6}
                                                      h={6}
                                                      w={6}
                                                    />
                                                  )}
                                                  {group.type}
                                                </Text>

                                                {group.subrows.some(
                                                  (doc) => doc.isNew
                                                ) && <NewBadge />}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <Spacer />
                                        {group.subrows.some(
                                          (doc) =>
                                            !doc.status ||
                                            !doc.status.status ||
                                            doc?.status?.status !==
                                              EXTRACTIONSTATES.Completed
                                        ) && (
                                          <Tooltip label="Processing documents">
                                            <CircularProgress
                                              isIndeterminate
                                              size={8}
                                            />
                                          </Tooltip>
                                        )}
                                        {/* view summaries button for categories */}
                                        {superGroup.super_class !==
                                          "Processing" && (
                                          <IconButton
                                            variant="primaryText"
                                            icon={
                                              <MdOutlineLibraryBooks
                                                size={18}
                                              />
                                            }
                                            aria-label="test"
                                            onClick={() => {
                                              const { super_class } =
                                                superGroup;
                                              const currentGroup = group.type;
                                              const firstDocumentId =
                                                group.subrows[0].id;

                                              const path = `/individual/${uid}/${visaTypeInParam}/documents/${super_class}/extracted-information?category=${currentGroup}&docId=${firstDocumentId}`;
                                              navigate(path);
                                            }}
                                          />
                                        )}
                                      </div>
                                    </Td>
                                  </GroupedDiv>

                                  {/* subrows */}
                                  <SortableContext
                                    key={`sub-${group.type}`}
                                    items={group.subrows.map((subrow) => ({
                                      id: subrow.id!
                                    }))}
                                  >
                                    {group.expanded &&
                                      group.subrows.map((document, i) => (
                                        <SubGroupDiv
                                          id={document.id}
                                          key={document.id}
                                          metadata={{
                                            group: group.type,
                                            superClass: superGroup.super_class
                                          }}
                                        >
                                          <Td>
                                            <div className="flex gap-2 items-center">
                                              <RowDragHandleCell
                                                rowId={document.id ?? ""}
                                              />
                                              {`${index + 1}.${i + 1}`}
                                              <div className="flex">
                                                <div className="flex flex-col">
                                                  <DocumentRowNewBadge
                                                    document={document}
                                                  />
                                                  <p>{document?.type ?? ""}</p>
                                                  <small className="text-black text-[10px]">
                                                    {document?.general_class?.toString()}
                                                  </small>
                                                </div>
                                              </div>
                                            </div>
                                          </Td>
                                          <Td>
                                            {document.criterion !==
                                              "Processing" &&
                                            !document.autoTitle ? (
                                              <Skeleton w={20} h={8} />
                                            ) : (
                                              <Tooltip
                                                label={
                                                  isAiToggled &&
                                                  document.criterion !==
                                                    "Processing"
                                                    ? document?.autoTitle
                                                    : document?.docNames
                                                }
                                              >
                                                <div className="flex">
                                                  <Text
                                                    noOfLines={2}
                                                    textColor="text.graySecondary.smog"
                                                  >
                                                    {isAiToggled &&
                                                    document.criterion !==
                                                      "Processing"
                                                      ? document?.autoTitle
                                                      : document?.docNames}
                                                  </Text>
                                                </div>
                                              </Tooltip>
                                            )}
                                          </Td>

                                          <Td>
                                            <Flex
                                              gap={2}
                                              justify="end"
                                              alignItems="center"
                                            >
                                              <Td border="none">
                                                <DocumentRowStatus
                                                  document={document}
                                                />
                                              </Td>
                                              <IconButton
                                                variant="filledIconButton"
                                                icon={<ExternalLinkIcon />}
                                                onClick={() => {
                                                  openFilePreview(
                                                    document.docUrl
                                                  );
                                                  updateDocumentNewField(
                                                    document
                                                  );
                                                }}
                                                aria-label=""
                                              />
                                              {superGroup.super_class !==
                                                "Processing" && (
                                                <>
                                                  <IconButton
                                                    variant="filledIconButton"
                                                    icon={<DeleteIcon />}
                                                    onClick={() => {
                                                      handleDeleteClick(
                                                        document
                                                      );
                                                    }}
                                                    aria-label=""
                                                  />
                                                  <IconButton
                                                    variant="filledIconButton"
                                                    icon={<EditIcon />}
                                                    onClick={() =>
                                                      handleEditClick(document)
                                                    }
                                                    aria-label=""
                                                  />
                                                </>
                                              )}
                                            </Flex>
                                          </Td>
                                        </SubGroupDiv>
                                      ))}
                                  </SortableContext>
                                </>
                              )
                          )}
                        </SortableContext>
                      </>
                    )
                )
              )}
            </Tbody>
          </Table>
        </div>
      </DndContext>
      {documentToEdit !== undefined && (
        <EditMainDocModal
          isDeleting={false}
          document={documentToEdit}
          headerText="Edit Main Document"
          isOpen={isEditOpen}
          onClose={onEditClose}
        />
      )}

      {/* delete modal */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
            }}
          />
          <ModalBody pb={6}>
            Are you sure you want to delete the document?
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="destructiveFilled"
              ml={3}
              isLoading={isDeleting}
              onClick={async () => {
                // edge case update documents to an empty array
                // before component gets unmounted

                if (groupedDocuments && groupedDocuments.length === 1) {
                  updateCurrentOrder([]);
                }

                if (documentToDelete?.docRef) {
                  setIsDeleting(true);

                  // soft delete
                  const docRef = doc(db, documentToDelete?.docRef ?? "");
                  await updateDoc(docRef, { isDeleted: true });

                  setIsDeleting(false);

                  onClose();
                }
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
