import {
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  Search2Icon,
  TriangleDownIcon,
  TriangleUpIcon
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Skeleton,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { doc, updateDoc } from "firebase/firestore";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { db } from "../../api/firebaseApi";
import { EXTRACTIONSTATES } from "../../redux/extraction-jobs/extractionJobsSlice";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { CustomTemplateType, templateTypeMap } from "../../types/drafts.type";
import { DATABASE, VISAVALUE } from "../../types/tables-data";
import { CustomAlertDialog } from "./CustomAlertDialog";
import { VisaTag } from "./VisaTag";
import { openFilePreview } from "../../helpers/helpers";

/** ======================
 *  Utility types/defs
 *  ====================== */

interface BaseEntityFields {
  status?: {
    status?: string;
  };
  id: string;
}

export interface GroupedDocument<T> {
  type: string;
  index: number;
  subrows: T[];
  expanded: boolean;
}

interface GroupedTableProps<T> {
  items: T[];
  setGroupedItems: (grouped: GroupedDocument<T>[]) => void;
  updateOrder: (grouped: GroupedDocument<T>[]) => void;
  isLoading: boolean;
  groupBy: keyof T;
  feature: string;
  searchPlaceholder?: string;
}

/** ===========================
 * GroupedTable
 *  =========================== */

export const GroupedTable = <T extends BaseEntityFields>({
  items,
  setGroupedItems,
  updateOrder,
  isLoading,
  groupBy,
  feature,
  searchPlaceholder = "Search"
}: GroupedTableProps<T>) => {
  const navigate = useNavigate();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [groupedItems, setGroupedItemsState] = useState<GroupedDocument<T>[]>(
    []
  );
  const [filter, setFilter] = useState("");

  // Keep track of which groups are expanded/collapsed manually.
  const [expansionState, setExpansionState] = useState<Record<string, boolean>>(
    {}
  );

  const lawyerId = useSelector(lawyerSelectors.selectUid);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(
    null
  );

  // ----------------------------------
  //  Single-column sorting per header
  // ----------------------------------
  type SortKey = "templateName" | "visa" | "uploadedByName";
  const [sortConfig, setSortConfig] = useState<{
    column: SortKey | null;
    direction: "asc" | "desc";
  }>({ column: null, direction: "asc" });

  const handleHeaderClick = useCallback(
    (columnId: SortKey) => {
      setSortConfig((prev) => {
        // If same column, toggle asc/desc
        if (prev.column === columnId) {
          return {
            column: columnId,
            direction: prev.direction === "asc" ? "desc" : "asc"
          };
        }
        // Otherwise switch to new column, default asc
        return { column: columnId, direction: "asc" };
      });
    },
    [setSortConfig]
  );

  // -----------------------
  // Pagination
  // -----------------------
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(items.length / pageSize);

  const handlePreviousPage = () => {
    setPageIndex((p) => Math.max(p - 1, 0));
  };
  const handleNextPage = () => {
    if ((pageIndex + 1) * pageSize < items.length) {
      setPageIndex((p) => p + 1);
    }
  };

  // -----------------------
  //  Grouping logic
  // -----------------------
  const groupItems = useCallback(() => {
    const grouped = items.reduce((acc, item) => {
      const groupKey = String(item[groupBy]);

      if (!acc[groupKey]) {
        acc[groupKey] = {
          type: groupKey,
          index: Object.keys(acc).length,
          subrows: [],
          expanded: true // Default to expanded
        };
      }
      acc[groupKey].subrows.push(item);
      return acc;
    }, {} as Record<string, GroupedDocument<T>>);

    const groupedArray = Object.values(grouped);
    setGroupedItemsState(groupedArray);
    setGroupedItems(groupedArray);
  }, [items, groupBy, setGroupedItems]);

  useEffect(() => {
    groupItems();
  }, [items, groupBy, groupItems]);

  const toggleExpand = useCallback((type: string) => {
    setGroupedItemsState((prev) =>
      prev.map((group) =>
        group.type === type ? { ...group, expanded: !group.expanded } : group
      )
    );
  }, []);

  // -----------------------
  //  Drag End
  // -----------------------
  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (active && over) {
        const activeIndex = groupedItems.findIndex(
          (group) => `group-${group.type}` === active.id
        );
        const overIndex = groupedItems.findIndex(
          (group) => `group-${group.type}` === over.id
        );
        if (
          activeIndex !== -1 &&
          overIndex !== -1 &&
          activeIndex !== overIndex
        ) {
          const updatedGroups = arrayMove(groupedItems, activeIndex, overIndex);
          setGroupedItems(updatedGroups);
          updateOrder(updatedGroups);
        }
      }
    },
    [groupedItems, setGroupedItems, updateOrder]
  );

  // -----------------------
  //  Filtering by group type
  // -----------------------
  // Fix: Filter by group type OR any relevant subrow fields
  const filteredGroups = groupedItems
    .map((group) => {
      const filteredSubrows = group.subrows.filter((sub) => {
        const templateName = (sub as any).templateName?.toLowerCase() || "";
        const uploadedBy = (sub as any).uploadedByName?.toLowerCase() || "";
        const groupType = group.type.toLowerCase();
        const f = filter.toLowerCase();

        return (
          groupType.includes(f) ||
          templateName.includes(f) ||
          uploadedBy.includes(f)
        );
      });
      // If at least one subrow matches, keep the group.
      if (filteredSubrows.length > 0) {
        return { ...group, subrows: filteredSubrows };
      }
      return null;
    })
    .filter((grp): grp is GroupedDocument<T> => grp !== null);

  // Sort each group’s subrows if a column is selected
  const sortedGroups = filteredGroups.map((group) => {
    if (sortConfig.column) {
      const { column, direction } = sortConfig;
      const sortedSubrows = [...group.subrows].sort((a, b) => {
        const aVal = (a as any)[column] ?? "";
        const bVal = (b as any)[column] ?? "";
        if (aVal < bVal) return direction === "asc" ? -1 : 1;
        if (aVal > bVal) return direction === "asc" ? 1 : -1;
        return 0;
      });
      return { ...group, subrows: sortedSubrows };
    }
    return group;
  });

  const paginatedGroups = sortedGroups.slice(
    pageIndex * pageSize,
    pageIndex * pageSize + pageSize
  );

  // -----------------------
  //  Deletion
  // -----------------------
  const handleDelete = async () => {
    if (!templateIdToDelete) return;
    const templateDocRef = doc(
      db,
      DATABASE.CUSTOM_TEMPLATES,
      lawyerId,
      "custom_templates",
      templateIdToDelete
    );
    setIsDeleting(true);
    await updateDoc(templateDocRef, { isDeleted: true });
    setIsDeleting(false);
    setTemplateIdToDelete(null);
    onClose();
  };

  // -----------------------
  //  Edit / Navigation
  // -----------------------
  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    templateId: string
  ) => {
    e.stopPropagation();
    navigate(`/studio/templates/edit/${templateId}`);
  };

  const handleRowClick = (templateId: string) => {
    if (templateId) navigate(`edit/${templateId}`);
  };

  // Which columns we show
  const getColumns = () => {
    switch (feature) {
      case "templates":
        return ["Template", "Visa", "Uploaded By", "Actions"];
      case "cases":
        return ["Case ID", "Client Name", "Status", "Actions"];
      default:
        return [];
    }
  };

  const columns = getColumns();

  const renderColumns = (subrow: T, column: string) => {
    switch (feature) {
      case "templates":
        switch (column) {
          case "Template":
            return (
              <Box ml={16}>
                <Flex>
                  <Box fontWeight="md">{(subrow as any).templateName}</Box>
                  <ExternalLinkIcon
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      openFilePreview((subrow as any).docUrl);
                    }}
                    ml={2}
                  />
                </Flex>
                <Box fontSize="sm" color="gray.500">
                  {new Date((subrow as any).created_at).toLocaleDateString(
                    "en-US",
                    {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric"
                    }
                  )}
                </Box>
              </Box>
            );
          case "Visa":
            return (
              <VisaTag visaType={(subrow as any).visa ?? VISAVALUE.EMPTY} />
            );
          case "Uploaded By":
            return (subrow as any).uploadedByName;
          case "Actions":
            return (
              <Flex gap={2} justifyContent="start">
                <IconButton
                  variant="filledIconButton"
                  icon={<DeleteIcon />}
                  isDisabled={subrow.status?.status === "Processing"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTemplateIdToDelete(subrow.id);
                    onOpen();
                  }}
                  aria-label=""
                />
                <IconButton
                  variant="filledIconButton"
                  icon={<EditIcon />}
                  isDisabled={subrow.status?.status === "Failed"}
                  onClick={(e) => handleEdit(e, subrow.id!)}
                  aria-label=""
                />
              </Flex>
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  // For each column header, decide if we show an up/down arrow
  const getSortIcon = (col: string) => {
    if (!sortConfig.column || sortConfig.column !== col) return null;
    return sortConfig.direction === "asc" ? (
      <TriangleUpIcon ml={1} />
    ) : (
      <TriangleDownIcon ml={1} />
    );
  };

  return (
    <Box p={4}>
      {/* Top bar: Search, plus "Viewing # templates" + "Add new template" */}
      <Flex mb={4} alignItems="center">
        <InputGroup w="300px">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input
            bg="white"
            type="search"
            size="md"
            placeholder={searchPlaceholder}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </InputGroup>

        <Spacer />

        <Flex alignItems="center" gap={4}>
          <Text whiteSpace="nowrap">Viewing {items.length} templates</Text>
        </Flex>
      </Flex>

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        <Table variant="stripedHover">
          <Thead>
            <Tr>
              {columns.map((col) => {
                // If col matches one of our sort keys, handle click to sort
                let sortKey: SortKey | null = null;
                if (col === "Template") sortKey = "templateName";
                if (col === "Visa") sortKey = "visa";
                if (col === "Uploaded By") sortKey = "uploadedByName";

                return (
                  <Th
                    key={col}
                    onClick={() => {
                      if (sortKey) handleHeaderClick(sortKey);
                    }}
                    cursor={sortKey ? "pointer" : "default"}
                  >
                    <Flex alignItems="center">
                      {col}
                      {sortKey && getSortIcon(sortKey)}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <CircularProgress isIndeterminate mx="auto" />
                </Td>
              </Tr>
            ) : (
              <SortableContext
                items={paginatedGroups.map((group) => `group-${group.type}`)}
              >
                {paginatedGroups.map((group) => (
                  <Fragment key={group.type}>
                    {/* Group Header Row */}
                    <Tr>
                      <Td>
                        <Flex alignItems="center">
                          <IconButton
                            mr={2}
                            onClick={() => toggleExpand(group.type)}
                            icon={
                              group.expanded ? (
                                <MdOutlineExpandLess />
                              ) : (
                                <MdOutlineExpandMore />
                              )
                            }
                            aria-label="Expand/Collapse"
                            variant="ghost"
                          />
                          <Box
                            p={2}
                            borderRadius="md"
                            border="2px solid"
                            borderColor="text.display.light"
                          >
                            {/* Convert from raw type to user-friendly text */}
                            {templateTypeMap[
                              group.type as CustomTemplateType
                            ] || group.type}
                          </Box>
                        </Flex>
                      </Td>
                      {/* Empty cells for the rest of the row */}
                      {columns.slice(1).map((_, idx) => (
                        <Td key={idx} />
                      ))}
                    </Tr>

                    {/* Subrows */}
                    {group.expanded &&
                      group.subrows.map((subrow) =>
                        subrow?.status?.status ===
                        EXTRACTIONSTATES.Processing ? (
                          <Tr key={subrow.id}>
                            {columns.map((col, idx) => (
                              <Td key={idx}>
                                <Skeleton height="20px" borderRadius="md" />
                              </Td>
                            ))}
                          </Tr>
                        ) : (
                          <Tr
                            key={subrow.id}
                            cursor="pointer"
                            onClick={() => handleRowClick(subrow.id)}
                          >
                            {columns.map((col) => (
                              <Td key={col}>{renderColumns(subrow, col)}</Td>
                            ))}
                          </Tr>
                        )
                      )}
                  </Fragment>
                ))}
              </SortableContext>
            )}
          </Tbody>
        </Table>
      </DndContext>

      {/* Pagination controls at bottom */}
      <Flex mt={4} alignItems="center" justifyContent="flex-end" gap={4}>
        <Text>Templates per page:</Text>
        <Select
          width="80px"
          bg="white"
          value={pageSize}
          onChange={(e) => {
            setPageIndex(0);
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>

        <Flex alignItems="center" gap={2}>
          <Button
            variant="outlineIconButton"
            leftIcon={<FaChevronLeft />}
            onClick={handlePreviousPage}
            isDisabled={pageIndex === 0}
          >
            Previous
          </Button>

          <Text>
            Page {pageIndex + 1} of {pageCount}
          </Text>

          <Button
            variant="outlineIconButton"
            rightIcon={<FaChevronRight />}
            onClick={handleNextPage}
            isDisabled={pageIndex >= pageCount - 1 || pageCount === 0}
          >
            Next
          </Button>
        </Flex>
      </Flex>

      {/* Delete Confirmation Alert */}
      <CustomAlertDialog
        withButton={false}
        alertTitle="WARNING!"
        alertType="delete"
        leastDestructiveRef={cancelRef}
        close={onClose}
        confirm={handleDelete}
        isOpen={isOpen}
        isLoading={isDeleting}
      >
        <Text>
          You are about to permanently delete this template and all data
          associated with it. This cannot be undone.
        </Text>
        <Text mt={2}>Are you sure you want to continue?</Text>
      </CustomAlertDialog>
    </Box>
  );
};
