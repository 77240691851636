import {
  Box,
  Button,
  CircularProgress,
  Container,
  Flex,
  useToast
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useFirestoreDocument from "../../../../hooks/useFirestoreDocument";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { DraftDocument } from "../../../../types/drafts.type";
import { DATABASE } from "../../../../types/tables-data";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SpecialHeading } from "../../PanelLayout";
import { SectionWrapper } from "../../../common/SectionWrapper";

export const EditExhibitList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { id, expertId, visaType } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const editorRef = useRef(null);
  const [value, setValue] = useState("");

  const { document, updateDocument, loading } =
    useFirestoreDocument<DraftDocument>(DATABASE.DRAFTS, uid, false);

  useEffect(() => {
    if (document?.exhibit_list) {
      setValue(document.exhibit_list.text);
    }
  }, [document]);

  return (
    <SectionWrapper
      bg="white"
      withIndividualEditBox
      backTargetPage="Drafts"
      onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
    >
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <SpecialHeading title="Exhibit List" backNav />
        {loading ? (
          <CircularProgress isIndeterminate />
        ) : (
          <div className="w-full">
            {document?.exhibit_list ? (
              <RichTextEditor
                text={document.exhibit_list.html || document.exhibit_list.text}
                saveText={(html, plainText) =>
                  updateDocument({
                    ...document,
                    exhibit_list: { text: plainText, html }
                  })
                }
              />
            ) : (
              <Box fontSize="18px">
                Click Generate in the previous page for a first draft
              </Box>
            )}
          </div>
        )}
        <Flex flexDirection="row" columnGap={4}>
          <Button
            my={4}
            variant="secondaryOutline"
            onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
            width="fit-content"
          >
            Back
          </Button>
        </Flex>
      </Flex>
    </SectionWrapper>
  );
};
