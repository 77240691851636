import { VISAVALUE } from "./tables-data";

export enum Feature {
  // TABS //
  DOCUMENTS = "documents",
  QUESTIONNAIRES = "questionnaires",
  CASE_RESEARCH = "case_research",
  DRAFTS = "drafts",
  PACKET = "packet",
  EXPERT_LETTERS = "drafts.experts",
  EXHIBIT_LIST = "drafts.exhibit_list",
  SOP = "drafts.sop"
}

// Exclusion Map (Specify Features to Exclude for Each Visa Type)
export const visaExclusionMap: Partial<Record<VISAVALUE, Feature[]>> = {
  [VISAVALUE.H1B]: [Feature.CASE_RESEARCH, Feature.EXPERT_LETTERS],
  [VISAVALUE.EB1C]: [Feature.CASE_RESEARCH,Feature.EXPERT_LETTERS],
  [VISAVALUE.L1A]: [Feature.EXPERT_LETTERS]
};
