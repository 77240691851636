import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  SkeletonText,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { db } from "../../../../api/firebaseApi";
import { SelectField } from "../../../../forms/SelectField";
import { scrollToElementByRef } from "../../../../helpers/dom_helpers";
import {
  downloadFileAsBlob,
  getFileName,
  handleUnsupportedExtension
} from "../../../../helpers/file_helpers";
import {
  fetchFiles,
  isValidPhoneNumber,
  sleep
} from "../../../../helpers/helpers";
import { useAddFileToDB } from "../../../../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../../../../hooks/useDeleteFileFromDB";
import { useExpertLetterDrafts } from "../../../../hooks/useExpertLetterDrafts";
import { useGetFilesFromDB } from "../../../../hooks/useGetFilesFromDB";
import { useGetQuestionsByVisaType } from "../../../../hooks/useGetQuestionsByVisaType";
import { useSubscribeToJob } from "../../../../hooks/useSubscribeToJob";
import { useUpdateIndivFormsData } from "../../../../hooks/useUpdateIndivFormsData";
import { useUserRoleAndSubscription } from "../../../../hooks/useUserRoleAndSubscription";
import { ExpertLetter } from "../../../../redux/clients/clientsSlice";
import { documentSelectors } from "../../../../redux/documents/selectors";
import {
  EXTRACTIONSTATES,
  ExtractionStatus,
  JobType
} from "../../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../../redux/extraction-jobs/selectors";
import { formsSelectors } from "../../../../redux/forms/selectors";
import { setActiveExpertLetter } from "../../../../redux/individual/individualSlice";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { DATABASE } from "../../../../types/tables-data";
import { UploadFilesPlaceholder } from "../../../UploadFilesPlaceholder";
import { CustomBox } from "../../../common/CustomBox";
import { CustomButton } from "../../../common/CustomButton";
import { DatePicker } from "../../../common/DatePicker";
import { DropdownGeneric } from "../../../common/DropdownGeneric";
import { CustomPhoneInput } from "../../../common/PhoneInput";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SectionWrapper } from "../../../common/SectionWrapper";
import { SpecialHeading } from "../../PanelLayout";
import ExpertLetterModal from "../../individualDrafts/expertLetterDrafts/ExpertLetterDraftDialog";
import { ExpertInfoSkeleton } from "./FormSkeleton";

export const EditExpertLetter = () => {
  const { id, expertId, visaType } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    uid: userId,
    firstName,
    lastName
  } = useSelector(individualSelectors.selectAll);

  const uid = id || userId;

  const activeJobs = useSelector(extractionSelectors.selectActiveJobs);
  const reducedActiveJobs = activeJobs.map((job) => job.id);
  const evidenceDocuments =
    useSelector(documentSelectors.groupedDocuments)
      .find((doc) => doc.super_class === "Evidence")
      ?.groups.flatMap((group) => group.subrows.flat()) ?? [];

  const expertLetterForm = useSelector((state: any) =>
    formsSelectors.selectExpertLetterFormById(state, expertId ?? "")
  );
  const [selectedEvidenceIds, setSelectedEvidenceIds] = useState<string[]>([]);

  if (!expertLetterForm)
    return <p>no expert letter form with this id: {expertId}</p>;

  const { isIndividual } = useUserRoleAndSubscription();

  const [expertLetter, setExpertLetter] = useState<null | ExpertLetter>(null);

  useEffect(() => {
    if (expertLetterForm) setExpertLetter(expertLetterForm);
  }, []);

  const { onSubmitAddUpdateLetter, isLoadingUpdateFormsData } =
    useUpdateIndivFormsData(uid);

  const { filteredBlocks } = useGetQuestionsByVisaType({
    visaType,
    expertLetter: expertLetterForm
  });

  const { deleteFolderContents } = useDeleteFileFromDB();

  const { updateNestedField } = useExpertLetterDrafts(uid);

  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingExpert, setIsLoadingExpert] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [resumeUrl, setResumeUrl] = useState<string | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();

  const [docId, setDocId] = useState<string | undefined | null>(expertId);
  const [fileName, setFileName] = useState<string | null>(null);
  const highlightsRef = useRef<HTMLDivElement | null>(null);
  const [showHighlightsRequired, setShowHighlightsRequired] =
    useState<boolean>(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isProcessingInputs, setIsProcessingInputs] = useState(false);

  // Draft generation
  const [showGenerateDialog, setShowGenerateDialog] = useState<boolean>(false);
  const [currentExpertId, setCurrentExpertId] = useState<string | undefined>(
    undefined
  );
  const [isGenerateTapped, setIsGenerateTapped] = useState<boolean>(false);

  // Auto populate fields
  const { getFieldValue } = useSubscribeToJob();
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [extractedFields, setExtractedFields] = useState(null);

  const [isExtractingFields, setIsExtractingFields] = useState(false);

  const generateDocumentId = async (individualId: string) => {
    const docRef = await doc(
      collection(db, DATABASE.FORMS, `${individualId}`, "expert_letters")
    );
    if (docRef.id) {
      setDocId(docRef.id);
    }
    return docRef.id;
  };

  const [bstatus, setbstatus] = useState<ExtractionStatus | null>(null);

  // Note : Currently, we're not using this saving summary points is included with global form save
  const handleSaveSummaryPoints = async (
    summaryHtml: string,
    summary: string
  ) => {
    try {
      if (!uid) {
        throw new Error("User ID is missing. Please log in again.");
      }

      const expLetterId = docId ?? (await generateDocumentId(uid));

      let filePath;

      if (fileName === null && docId) {
        filePath = expertLetterForm?.filePath;
      } else {
        filePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${expLetterId}/${fileName}`;
      }

      const letterData = {
        summaryHtml,
        summary
      };

      if (expLetterId) {
        await onSubmitAddUpdateLetter({
          values: letterData,
          expertLetterId: expLetterId
        });
        setCurrentExpertId(expLetterId);
        navigate(`/individual/${id}/${visaType}/drafts`);
        toast({
          title: "Success",
          description: "Expert inputs was updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    } catch (error: any) {
      console.error("Error updating information:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while updating information.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right"
      });
      // Optionally, handle additional logic or state updates in case of an error.
    }
  };

  const updateExpertName = async (
    expertName: string,
    expertLetterId: string
  ) => {
    try {
      const docRef = doc(
        db,
        `${DATABASE.DRAFTS}/${uid}/expert_letters/${expertLetterId}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Document exists, submit the update
        await updateNestedField(
          expertLetterId,
          "extracted_argument.letter_author",
          expertName
        );
      } else {
        console.log("No such document exists!");
      }
    } catch (error) {
      console.error("Error checking document existence: ", error);
    }
  };

  const getInitialExpertLetterValues = (
    activeExpertLetter: Partial<ExpertLetter>
  ) => ({
    expertName: activeExpertLetter?.expertName || "",
    institution: activeExpertLetter?.institution || "",
    relationship: activeExpertLetter?.relationship || "",
    customRelationship: activeExpertLetter?.customRelationship || "",
    expertRole: activeExpertLetter?.expertRole || "",
    expertCurrent: activeExpertLetter?.expertCurrent || "",
    relationshipStartDate: activeExpertLetter?.relationshipStartDate || "",
    relationshipEndDate: activeExpertLetter?.relationshipEndDate || "",
    isWorkedTogether: activeExpertLetter?.isWorkedTogether || false,
    phoneNumber: activeExpertLetter?.phoneNumber || "",
    email: activeExpertLetter?.email || "",
    sharedOrganization: activeExpertLetter?.sharedOrganization || "",
    summary: activeExpertLetter?.summary || "",
    summaryHtml: activeExpertLetter?.summaryHtml || "",
    filePath: activeExpertLetter?.filePath || "",
    docNames: activeExpertLetter?.docNames || "",
    created_at: activeExpertLetter?.created_at || null,
    evidenceIds: activeExpertLetter?.evidenceIds || [],
    expertiseSourceList: activeExpertLetter?.expertiseSourceList || [],
    specificRoleList: activeExpertLetter?.specificRoleList || [],
    abilitiesList: activeExpertLetter?.abilitiesList || [],
    roleImportanceList: activeExpertLetter?.roleImportanceList || []
  });

  const formik = useFormik({
    initialValues: getInitialExpertLetterValues(expertLetterForm!),
    validationSchema: yup.object({
      expertName: yup
        .string()
        .required("Expert's Full name is required")
        .min(1),
      institution: yup.string().required("Institution is required"),
      relationship: yup.string().when("isWorkedTogether", {
        is: true,
        then: (schema) => schema.required("Relationship is required")
      }),
      customRelationship: yup.string().when("relationship", {
        is: "Other",
        then: (schema) => schema.required("Please specify the relationship")
      }),
      expertRole: yup.string(),
      expertCurrent: yup.string().required("Current role is required"),
      phoneNumber: yup
        .string()
        .nullable()
        .test(
          "is-valid-phone-number",
          "Invalid phone number",
          function (value: string | null | undefined) {
            if (value && value.trim() !== "" && value.trim() !== "+1") {
              return isValidPhoneNumber(value);
            }
            return true;
          }
        ),
      sharedOrganization: yup.string().when("isWorkedTogether", {
        is: true,
        then: (schema) =>
          schema.required(
            "Organization where applicant and expert worked together is required"
          )
      })
    }),
    // Custom validation
    validate: (values) => {
      const errors = { summary: "" };

      // Check if file, resumeUrl, or summary is missing
      if (!values.summary || values.summary.trim() === "") {
        setShowHighlightsRequired(true);

        // Scroll to resume highlights
        scrollToElementByRef(highlightsRef);

        // Return error to prevent submission
        errors.summary = "Resume highlights is required.";
        return errors;
      }

      // If there are any Formik errors, scroll to the first one
      const errorFields = Object.keys(formik.errors);
      if (errorFields.length > 0) {
        const firstErrorField = document.getElementById(errorFields[0]);
        if (firstErrorField) {
          scrollToElementByRef(firstErrorField);
          firstErrorField.focus();
        }
      }

      setValidateAfterSubmit(false);

      // If no errors, return an empty object or undefined to proceed with submission
      return {};
    },
    enableReinitialize: true,
    validateOnChange: validateAfterSubmit,

    onSubmit: async (values) => {
      try {
        if (uid) {
          const expLetterId = docId ?? (await generateDocumentId(uid));

          setCurrentExpertId(expLetterId);

          const filePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${expLetterId}/${fileName}`;

          const letterData = {
            expertName: values.expertName,
            institution: values.institution,
            relationship: values.relationship,
            customRelationship: values.customRelationship,
            expertRole: values.expertRole,
            expertCurrent: values.expertCurrent,
            relationshipStartDate: values.relationshipStartDate,
            relationshipEndDate: values.relationshipEndDate,
            phoneNumber: values.phoneNumber === "+1" ? "" : values.phoneNumber,
            email: values.email,
            sharedOrganization: values?.sharedOrganization,
            uploadedByUid: uid,
            uploadedByName: `${firstName} ${lastName}`,
            expertiseSourceList: values.expertiseSourceList ?? [],
            specificRoleList: values.specificRoleList ?? [],
            abilitiesList: values.abilitiesList ?? [],
            roleImportanceList: values.roleImportanceList ?? [],
            isWorkedTogether: values.isWorkedTogether,
            docNames: fileName ?? values.expertName,
            uid: expLetterId,
            filePath: fileName ? filePath : undefined,
            summaryHtml: values.summaryHtml,
            summary: values.summary,
            evidenceIds: selectedEvidenceIds,
            created_at:
              values.created_at !== undefined ? values.created_at! : Date.now(),
            last_updated_at: Date.now()
          };

          if (expLetterId) {
            console.log("letter data in save now", letterData);
            setCurrentExpertId(expLetterId);
            await onSubmitAddUpdateLetter({
              values: letterData,
              expertLetterId: expLetterId
            });
            // Temporary fix to ensure the expert name is correctly applied to the draft
            await updateExpertName(values.expertName, expLetterId);
            setShowGenerateDialog(true);
          }
        } else {
          throw new Error("User ID is missing. Please log in again.");
        }
      } catch (error: any) {
        console.error("Error updating information:", error);
        toast({
          title: "Error",
          description:
            error.message || "An error occurred while updating information.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right"
        });
        // Optionally, handle additional logic or state updates in case of an error.
      }
    }
  });

  const summaryRef = useRef(formik.values.summaryHtml);

  useEffect(() => {
    const handler = setTimeout(() => {
      summaryRef.current = formik.values.summaryHtml;

      if (formik.values.summaryHtml?.trim() !== "") {
        setShowHighlightsRequired(false);
      }
    }, 300); // Debounce delay in milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [formik.values.summaryHtml]);

  function looksLikeAUSNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return false;
    }

    // Check if the phone number contains a + sign
    if (phoneNumber.includes("+")) {
      return false;
    }

    // Use a regular expression to match all digits in the string
    const digitMatches = phoneNumber.match(/\d/g);

    // Check if there are exactly 10 digits
    return digitMatches && digitMatches.length === 10;
  }

  const handlePopulateFields = async () => {
    setIsLoadingFields(true);

    try {
      const firestoreRef = doc(
        db,
        DATABASE.FORMS,
        `${uid}`,
        "expert_letters",
        `${expertId}`
      );
      const data = await getFieldValue(firestoreRef, "extracted_fields");
      const summaryFromDb = await getFieldValue(firestoreRef, "summary");
      const newFilePath = await getFieldValue(firestoreRef, "filePath");
      const summaryHtmlFromDb = await getFieldValue(
        firestoreRef,
        "summaryHtml"
      );
      // TODO : Refactor to a single call
      const abilitiesList = await getFieldValue(firestoreRef, "abilitiesList");
      const specificRoleList = await getFieldValue(
        firestoreRef,
        "specificRoleList"
      );
      const expertiseSourceList = await getFieldValue(
        firestoreRef,
        "expertiseSourceList"
      );
      const roleImportanceList = await getFieldValue(
        firestoreRef,
        "roleImportanceList"
      );
      setExtractedFields(data);

      if ("resume_full_name" in data) {
        setExpertLetter((prev: any) => ({
          ...prev,
          expertName: data.resume_full_name
        }));
      }
      // Special handling for phone numbers for US numbers
      if (looksLikeAUSNumber(data.resume_phone_number)) {
        data.resume_phone_number = `+1${data.resume_phone_number}`;
      }

      // Set form values
      formik.setValues({
        ...formik.values,
        expertName: data.resume_full_name || formik.values.expertName,
        institution: data.resume_last_organization || formik.values.institution,
        expertCurrent: data.resume_last_role || formik.values.expertCurrent,
        email: data.resume_email_address || formik.values.email,
        phoneNumber: data.resume_phone_number || formik.values.phoneNumber,
        summary: formik.values.summary || summaryFromDb || "",
        summaryHtml: formik.values.summaryHtml?.trim()
          ? formik.values.summaryHtml
          : summaryHtmlFromDb || "",
        filePath: newFilePath,
        abilitiesList: abilitiesList || formik.values.abilitiesList,
        specificRoleList: specificRoleList || formik.values.specificRoleList,
        expertiseSourceList:
          expertiseSourceList || formik.values.expertiseSourceList,
        roleImportanceList:
          roleImportanceList || formik.values.roleImportanceList
      });

      // setExpertName(data.resume_full_name || formik.values.expertName);
    } catch (error) {
      toast({
        title: "Error",
        description: `An error occurred while fetching data : ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right"
      });
    } finally {
      setIsExtractingFields(false);
    }
  };

  const bstatusRef = useRef(bstatus);

  useEffect(() => {
    bstatusRef.current = bstatus;

    if (bstatus?.status || docId) {
      const isProcessing =
        (bstatus?.status &&
          [EXTRACTIONSTATES.Processing, EXTRACTIONSTATES.Pending].includes(
            bstatus.status
          )) ||
        (docId && reducedActiveJobs.includes(docId));

      setIsProcessingInputs(isProcessing || false);
    }
  }, [bstatus, docId]);

  useEffect(() => {
    if (uid && docId) {
      try {
        const docRef = doc(
          collection(db, DATABASE.FORMS, `${uid}`, "expert_letters"),
          docId
        );

        const unsubscribe = onSnapshot(
          docRef,
          (doc) => {
            try {
              if (doc.exists()) {
                const data = doc.data();
                const currentStatus = data.status;
                if (
                  bstatusRef.current?.status === "Processing" &&
                  currentStatus?.status === "Completed"
                ) {
                  console.log("auto populating");
                  handlePopulateFields();
                }
                setbstatus(currentStatus);
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.error("Error processing document data:", error);
            }
          },
          (error) => {
            console.error("Error fetching document:", error);
            console.log(uid);
            console.log(docId);
          }
        );

        return () => unsubscribe();
      } catch (error) {
        console.error("Error setting up Firestore snapshot listener:", error);
      }
    }
    return () => {};
  }, [docId, uid]);

  const uploadImage = async (docId: string, file: File) => {
    setIsUploadingFile(true);
    if (file && docId) {
      try {
        const dirPath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${docId}`;

        // Delete any existing files at the specified path
        await deleteFolderContents(dirPath);

        // Upload the new file
        const { file: uploadedFile, path: uploadedPath } =
          await onSubmitAddFile(file, `${dirPath}/${file.name}`, true);

        // setSelectedFile(null);
        await onSubmitGetFiles(dirPath);

        // Initially we only submit the file metadata to firestore to trigger auto fields extraction

        const letter: ExpertLetter = {
          uploadDate: Date.now(),
          uploadedByUid: uid,
          uploadedByName: `${firstName} ${lastName}`,
          uid: docId,
          docNames: uploadedFile.name,
          filePath: uploadedPath,
          isDeleted: false
        };

        await onSubmitAddUpdateLetter({
          values: letter,
          expertLetterId: docId
        });
      } catch (e) {
        console.log(e);
      } finally {
        // This delay is a little hack just to make the loading seamless as we wait for the BE status to become processing.
        await sleep(4000);
        setIsUploadingFile(false);
        setIsLoadingDoc(false);
        setShowHighlightsRequired(false);
      }
    }
  };

  useEffect(() => {
    setIsLoadingExpert(true);
    if (storageFiles.length) {
      const lastFileRef = storageFiles[storageFiles.length - 1];
      downloadFileAsBlob(lastFileRef)
        .then((objectURL: string) => {
          setResumeUrl(objectURL);
          setFileName(lastFileRef.name);
        })
        .finally(() => {
          setIsLoadingExpert(false);
        })
        .catch((error) => {
          console.error("Error downloading file as blob:", error);
          setIsLoadingExpert(false);
        });
    }
  }, [storageFiles]);

  const handleFileChange = async (
    e?: React.ChangeEvent<HTMLInputElement> | null,
    files?: File[] | null
  ) => {
    const newExpertLetterDocId = docId ?? (await generateDocumentId(uid));
    if (e?.target?.files?.length || files?.length) {
      const file = e?.target?.files?.[0] || files?.[0] || null;

      if (handleUnsupportedExtension(file?.name || "N/A", fileRef)) {
        return; // Exit if the file extension is unsupported
      }
      if (file && newExpertLetterDocId) {
        setSelectedFile(file);
        setIsExtractingFields(true);
        uploadImage(newExpertLetterDocId, file);
      }
    }
  };

  const handleSave = () => {
    setValidateAfterSubmit(true);
    formik.handleSubmit();
  };

  // TODO : Refactor with the default formik submit handler
  const handleSaveForLater = async () => {
    if (!formik.values.summary || formik.values.summary.trim() === "") {
      setShowHighlightsRequired(true);
      // Scroll to resume highlights
      scrollToElementByRef(highlightsRef);
      return;
    }
    const errors = await formik.validateForm();
    const errorFields = Object.keys(formik.errors);
    if (errorFields.length > 0) {
      const firstErrorField = document.getElementById(errorFields[0]);
      if (firstErrorField) {
        scrollToElementByRef(firstErrorField);
        firstErrorField.focus();
      }
    }
    setValidateAfterSubmit(false);
    if (Object.keys(errors).length === 0) {
      try {
        const expLetterId = docId ?? (await generateDocumentId(uid));

        const letterData = {
          expertName: formik.values.expertName,
          institution: formik.values.institution,
          relationship: formik.values.relationship,
          customRelationship: formik.values.customRelationship,
          expertRole: formik.values.expertRole,
          expertCurrent: formik.values.expertCurrent,
          relationshipStartDate: formik.values.relationshipStartDate,
          relationshipEndDate: formik.values.relationshipEndDate,
          phoneNumber:
            formik.values.phoneNumber === "+1" ? "" : formik.values.phoneNumber,
          email: formik.values.email,
          sharedOrganization: formik.values.sharedOrganization,
          expertiseSourceList: formik.values.expertiseSourceList ?? [],
          specificRoleList: formik.values.specificRoleList ?? [],
          abilitiesList: formik.values.abilitiesList ?? [],
          roleImportanceList: formik.values.roleImportanceList ?? [],
          isWorkedTogether: formik.values.isWorkedTogether,
          docNames: fileName ?? formik.values.expertName,
          uid: expLetterId,
          filePath: fileName ? formik.values.filePath : undefined,
          summaryHtml: formik.values.summaryHtml,
          summary: formik.values.summary,
          uploadedByUid: uid,
          uploadedByName: `${firstName} ${lastName}`,
          evidenceIds: selectedEvidenceIds,
          created_at: formik.values.created_at ?? Date.now(),
          last_updated_at: Date.now()
        };

        setCurrentExpertId(expLetterId);

        // Submit the data to the server
        await onSubmitAddUpdateLetter({
          values: letterData,
          expertLetterId: expLetterId,
          _useLoadingFlag: false
        });
        // Temporary fix to ensure the expert name is correctly applied to the draft
        await updateExpertName(formik.values.expertName, expLetterId);

        // Show success message and navigate to drafts
        toast({
          title: "Success",
          description: "Expert letter saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
        navigate(`/individual/${id}/${visaType}/drafts`);
      } catch (error: any) {
        toast({
          title: "Error",
          description:
            error.message || "An error occurred while saving the letter.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    } else {
      // Focus on the first field with validation errors
      const firstErrorField = document.getElementById(Object.keys(errors)[0]);
      if (firstErrorField) {
        scrollToElementByRef(firstErrorField);
        firstErrorField.focus();
      }
    }
  };

  const isSummarizingResume = useMemo(() => {
    return !!(
      bstatus?.status &&
      bstatus?.taskType &&
      [JobType.SummarizingResume, JobType.Extracting].includes(
        bstatus.taskType as JobType
      ) &&
      [EXTRACTIONSTATES.Pending, EXTRACTIONSTATES.Processing].includes(
        bstatus.status
      )
    );
  }, [bstatus?.status, bstatus?.taskType]);

  const onGenerateCancel = async (expertId: string) => {
    setShowGenerateDialog(false);
  };

  useEffect(() => {
    if (isGenerateTapped) {
      setShowGenerateDialog(false);
    }
  }, [isGenerateTapped]);

  const shouldShowGenerateBtn = !isIndividual;

  const isExpertUploaded = expertLetter && !expertLetter?.filePath;

  return (
    <SectionWrapper
      withIndividualEditBox
      bg="white"
      onClick={() => navigate(`/individual/${id}/${visaType}/drafts`)}
    >
      <form onSubmit={formik.handleSubmit}>
        <Flex flexDirection="column" rowGap="6px">
          <Skeleton maxH="36px" isLoaded={!isExtractingFields} w="fit-content">
            <SpecialHeading
              title={`Edit expert letter : ${expertLetter?.expertName}`}
              backNav
            />
          </Skeleton>

          {/* Resume upload section */}
          {isExpertUploaded && (
            <CustomBox type="info">
              <Flex flexDirection="column">
                <Text fontSize="18px">
                  Start by uploading the expert's resume.
                </Text>
              </Flex>
            </CustomBox>
          )}

          <UploadFilesPlaceholder
            allowUploadByCategory={false}
            tableRef="signed_expert_letters"
            isDisabled={isLoadingDoc}
            styleIndex={2}
            title={
              fileName ||
              getFileName(expertLetterForm?.filePath) ||
              "Click to upload expert’s resume and/or expert questionnaire"
            }
            subTitle="Click Here to Upload"
            onUpload={(files) => {
              const filesAsArray = Array.from(files!);
              handleFileChange(null, filesAsArray);
            }}
            uploaded={
              fileName !== null || expertLetterForm?.filePath !== undefined
            }
            fileName={fileName || getFileName(expertLetterForm?.filePath)}
          />

          {expertLetterForm && expertLetterForm?.autoOCRText === "" && (
            <CustomBox type="info">
              <Text fontSize="18px" fontStyle="italic">
                There was an error with the uploaded resume.
              </Text>
            </CustomBox>
          )}

          <Box gap="50px" w="100%">
            {isExtractingFields && (
              <CustomBox type="info">
                <Text fontSize="15px">
                  AI is now extracting the information from the document.
                </Text>
              </CustomBox>
            )}
            {/* Expert's information */}
            <Flex flexDirection="column" width="100%">
              <Heading size="md" color="primary.blue" my={6}>
                Expert's information
              </Heading>
              {isExtractingFields ? (
                <ExpertInfoSkeleton />
              ) : (
                <Flex flexDirection="column" gap={12} mt={4}>
                  <FormControl
                    variant="floating"
                    id="expertName"
                    isInvalid={
                      formik.touched.expertName && !!formik.errors.expertName
                    }
                  >
                    <Input
                      placeholder=" "
                      name="expertName"
                      type="text"
                      value={formik.values.expertName}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Full name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>
                      Expert's Full name is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="institution"
                    isInvalid={
                      formik.touched.institution && !!formik.errors.institution
                    }
                  >
                    <Input
                      placeholder=" "
                      name="institution"
                      type="text"
                      value={formik.values.institution}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Organization <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>Institution is required</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="expertCurrent"
                    isInvalid={
                      formik.touched.expertCurrent &&
                      !!formik.errors.expertCurrent
                    }
                  >
                    <Input
                      placeholder=" "
                      name="expertCurrent"
                      type="text"
                      value={formik.values.expertCurrent}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Current role <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>
                      Current role is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="email"
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  >
                    <Input
                      placeholder=" "
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Email</FormLabel>
                    <FormErrorMessage>Email is required</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="phoneNumber"
                    isInvalid={
                      formik.touched.phoneNumber && !!formik.errors.phoneNumber
                    }
                  >
                    <Box>
                      <CustomPhoneInput
                        value={formik.values.phoneNumber}
                        onChange={(value) =>
                          formik.setFieldValue("phoneNumber", value)
                        }
                        placeholder="Expert Phone Number"
                      />
                    </Box>
                    <FormErrorMessage>
                      Phone number is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              )}
            </Flex>

            <Divider my={8} />
            {/* Expert's qualifications */}

            <Flex flexDirection="column" gap={4} mt={8} width="100%">
              <Heading size="md" py={2}>
                Expert qualifications <span style={{ color: "red" }}>*</span>
              </Heading>

              <Flex pb={6} flexDirection="column" ref={highlightsRef}>
                {showHighlightsRequired && (
                  <Flex>
                    <Flex flexDirection="column" my={2} rowGap={2}>
                      <Text color="red" fontWeight={500}>
                        Expert's resume highlights is required to proceed.{" "}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                {!resumeUrl && !expertLetterForm?.filePath && (
                  <CustomBox type="info">
                    <Flex flexDirection="column">
                      <Text>
                        Upload the expert's resume to automatically generate key
                        highlights.
                      </Text>
                    </Flex>
                  </CustomBox>
                )}
                {isExtractingFields || isSummarizingResume ? (
                  <SkeletonText
                    mt={6}
                    width="62vw"
                    noOfLines={8}
                    spacing="18px"
                    skeletonHeight="12px"
                  />
                ) : (
                  <RichTextEditor
                    loading={isExtractingFields || isSummarizingResume}
                    customHeight="50vh"
                    // placeholder="Summarize the expert's qualifications here."
                    text={formik.values.summaryHtml || formik.values.summary}
                    saveText={(html: string, plainText: string) =>
                      handleSaveSummaryPoints(html, plainText)
                    }
                    updateText={(html, text) => {
                      formik.setFieldValue("summary", text || "", false);
                      formik.setFieldValue("summaryHtml", html || "", false);
                    }}
                    shouldShowSaveBtn={false}
                  />
                )}
              </Flex>
            </Flex>
          </Box>

          {/* Worked Together Section */}
          <div className="flex flex-col gap-4 mt-8">
            <FormControl id="isWorkedTogether">
              <Checkbox
                name="isWorkedTogether"
                isChecked={formik.values.isWorkedTogether}
                onChange={formik.handleChange}
                borderColor="black"
              >
                <Text m={6} fontWeight={600} fontSize={22}>
                  Check this box if expert worked with applicant
                </Text>
              </Checkbox>
            </FormControl>
            {formik.values.isWorkedTogether && (
              <>
                <SelectField
                  formikHelpers={[
                    formik.getFieldProps("relationship"),
                    formik.getFieldMeta("relationship"),
                    formik.getFieldHelpers("relationship")
                  ]}
                  placeHolder="Select one of the options"
                  label="Relationship"
                  name="relationship"
                  options={[
                    "Manager",
                    "Supervisor",
                    "Colleague",
                    "Acquaintance",
                    "Friend",
                    "Other"
                  ]}
                />
                {formik.values.relationship === "Other" && (
                  <FormControl
                    isRequired
                    variant="fixed"
                    id="customRelationship"
                    isInvalid={
                      formik.touched.customRelationship &&
                      !!formik.errors.customRelationship
                    }
                  >
                    <FormLabel>Please specify the relationship</FormLabel>
                    <Input
                      variant="outline"
                      placeholder=" "
                      name="customRelationship"
                      type="text"
                      value={formik.values.customRelationship}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      Relationship is required
                    </FormErrorMessage>
                  </FormControl>
                )}
                <FormControl
                  variant="fixed"
                  id="expertRole"
                  isInvalid={
                    formik.touched.expertRole && !!formik.errors.expertRole
                  }
                >
                  <FormLabel>Expert's role while working together</FormLabel>
                  <Input
                    placeholder=" "
                    name="expertRole"
                    type="text"
                    value={formik.values.expertRole}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>Expert role is required</FormErrorMessage>
                </FormControl>
                <FormControl
                  variant="fixed"
                  id="sharedOrganization"
                  isInvalid={
                    formik.touched.sharedOrganization &&
                    !!formik.errors.sharedOrganization
                  }
                >
                  <FormLabel>
                    Organization where applicant and expert worked together{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    placeholder=" "
                    name="sharedOrganization"
                    type="text"
                    value={formik.values.sharedOrganization}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    Organization where applicant and expert worked together is
                    required.
                  </FormErrorMessage>
                </FormControl>
                <FormControl variant="fixed" id="relationshipStartDate">
                  <FormLabel>Relationship start date</FormLabel>
                  <DatePicker
                    date={formik.values.relationshipStartDate}
                    handleChange={(date) => {
                      formik.setFieldValue("relationshipStartDate", date);
                    }}
                  />
                </FormControl>
                <FormControl variant="fixed" id="relationshipEndDate">
                  <FormLabel>Relationship end date</FormLabel>
                  <DatePicker
                    date={formik.values.relationshipEndDate}
                    handleChange={(date) => {
                      formik.setFieldValue("relationshipEndDate", date);
                    }}
                  />
                </FormControl>
              </>
            )}
          </div>

          {/* Evidences dropdown */}
          <Flex flexDirection="column" my={4}>
            <SpecialHeading title="Evidence to Highlight" />
            <Flex flexDirection="column" rowGap={4} py={4}>
              <Text size="sm">
                Optionally, select documents from the uploaded evidence to be
                referenced in the expert letter.
              </Text>
              <Flex w="100%">
                <DropdownGeneric
                  withShuffle
                  singleSelect={false}
                  withCheck
                  withView
                  withFilter
                  withPlusIcon
                  groupBy="criterion"
                  list={evidenceDocuments}
                  checkedItems={expertLetterForm?.evidenceIds}
                  mergingField="id"
                  searchField="autoTitle"
                  width={260}
                  getSelectedItems={(items) => {
                    const evidenceIds = items?.map((doc) => doc.id ?? "");
                    if (evidenceIds) setSelectedEvidenceIds(evidenceIds);
                  }}
                />
              </Flex>
            </Flex>
          </Flex>

          {/* Expert Questions */}
          <SpecialHeading title="Expert's questionnaire" />
          <Box w="100%">
            {!filteredBlocks && (
              <CircularProgress
                color="primary.blue"
                isIndeterminate
                height={8}
                w={8}
              />
            )}

            {isExtractingFields ? (
              // Show Skeletons while loading
              <Box>
                {Array(3) // Render 3 skeletons as placeholders
                  .fill("")
                  .map((_, i) => (
                    <Box
                      key={i}
                      p="15px"
                      borderWidth="1px"
                      borderRadius="md"
                      mb="10px"
                    >
                      <Skeleton height="20px" mb="10px" />
                      <Skeleton height="50px" />
                    </Box>
                  ))}
              </Box>
            ) : (
              // Render the actual content when loading is complete
              filteredBlocks &&
              filteredBlocks.map((block, i) => (
                <Box
                  key={i}
                  p="15px"
                  borderWidth="1px"
                  borderRadius="md"
                  mb="10px"
                >
                  <Text
                    fontSize="md"
                    color="primary.blue"
                    fontWeight="bold"
                    mb="10px"
                  >
                    {block.blockTitle}
                  </Text>
                  <Textarea
                    placeholder="Type your answer here..."
                    value={
                      block.variableTitle in formik.values &&
                      Array.isArray(
                        formik.values[
                          block.variableTitle as keyof typeof formik.values
                        ]
                      )
                        ? (
                            formik.values[
                              block.variableTitle as keyof typeof formik.values
                            ] as string[]
                          ).join("\n")
                        : ""
                    }
                    onChange={(e) => {
                      const newList = e.target.value.split("\n");
                      block.setList(newList);
                      formik.setFieldValue(block.variableTitle, newList);
                    }}
                    rows={5}
                  />
                </Box>
              ))
            )}
          </Box>
          <Flex m="30px" justifyContent="center" gap={4}>
            <Button
              my={4}
              variant="secondaryOutline"
              type="button"
              mx="15px"
              onClick={() => {
                navigate(`/individual/${id}/${visaType}/drafts`);
              }}
            >
              Cancel
            </Button>
            <Button
              my={4}
              variant="primaryFilled"
              onClick={() => {
                handleSaveForLater();
              }}
              isDisabled={isProcessingInputs}
            >
              Save
            </Button>
            {shouldShowGenerateBtn && (
              <CustomButton
                title="Generate"
                type="ai"
                onClick={handleSave}
                isLoading={isLoadingUpdateFormsData}
                isDisabled={isProcessingInputs}
              />
            )}
          </Flex>
        </Flex>
      </form>
      {/* Modals */}
      {currentExpertId && (
        <ExpertLetterModal
          setIsGenerateTapped={setIsGenerateTapped}
          expertId={currentExpertId}
          onCancel={() => {
            onGenerateCancel(currentExpertId);
          }}
          isOpen={showGenerateDialog && currentExpertId !== undefined}
        />
      )}
    </SectionWrapper>
  );
};
