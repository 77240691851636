import { Tag, TagLabel } from "@chakra-ui/react";
import React from "react";
import { VISAVALUE } from "../../types/tables-data";

interface VisaTagProps {
  visaType: VISAVALUE;
}

export const VisaTag: React.FC<VisaTagProps> = ({ visaType }) => {
  // Define color scheme for each visa type
  const colorScheme = {
    [VISAVALUE.EB1A]: "teal",
    [VISAVALUE.EB1B]: "teal",
    [VISAVALUE.EB1C]: "teal",
    [VISAVALUE.EB2NIW]: "teal",
    [VISAVALUE.H1B]: "green",
    [VISAVALUE.O1A]: "blue",
    [VISAVALUE.O1B]: "blue",
    [VISAVALUE.L1A]: "red",
    [VISAVALUE.EMPTY]: "gray"
  };

  return (
    <Tag
      borderWidth="1px"
      borderRadius="md"
      rounded="full"
      px={3}
      py={1}
      fontSize="sm"
      fontWeight="medium"
      borderColor={`${colorScheme[visaType]}.400`}
      color={`${colorScheme[visaType]}.700`}
      bg="white"
    >
      <TagLabel>{visaType || "N/A"}</TagLabel>
    </Tag>
  );
};
