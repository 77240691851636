import { useCallback, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";

import { FirestoreWrapper } from "../api/fireStoreWrapper";
import { updateClientCaseData } from "../redux/clients/clientsSlice";
import { ToastManager } from "../services/toast/toastManager";
import { DATABASE } from "../types/tables-data";

function useFirestoreDocument<T>(
  collectionPath: DATABASE,
  docId: string,
  listen = false,
  fullCollectionPath = "",
  disableToast = false // New parameter with default value
) {
  const [document, setDocument] = useState<Partial<T> | null>(null);
  const [liveDocument, setLiveDocument] = useState<Partial<T> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isFetchCalled, setIsFetchCalled] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  if (fullCollectionPath === "") {
    fullCollectionPath = collectionPath;
  }
  const firestoreWrapper = new FirestoreWrapper<T>(db, fullCollectionPath);

  const listenAndFetch = useCallback(async () => {
    try {
      const unsub = onSnapshot(doc(db, fullCollectionPath, docId), (doc) => {
        const data = doc.data();
        setDocument(data as T);
        setIsFetchCalled(true);
      });
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  }, [fullCollectionPath, docId]);

  const fetchDocument = useCallback(async () => {
    try {
      setLoading(true);
      const docData = await firestoreWrapper.read(docId);
      setDocument(docData);
      setLoading(false);
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  }, [fullCollectionPath, docId]);

  useEffect(() => {
    if (listen) {
      listenAndFetch();
    } else {
      fetchDocument();
    }
  }, [fetchDocument, listenAndFetch]);

  const createDocument = async (newData: Partial<T>) => {
    try {
      setLoading(true);
      const result = await firestoreWrapper.create(docId, newData);
      setDocument(newData);
      setLoading(false);

      if (!disableToast) {
        ToastManager.getInstance().showToast(
          collectionPath,
          "success",
          "added"
        );
      }
    } catch (err: any) {
      ToastManager.getInstance().showToast(
        collectionPath,
        "warning",
        "updated"
      );
      setError(err);
    }
  };

  const updateDocument = async (newData: Partial<T>) => {
    try {
      // setLoading(true);
      await firestoreWrapper.update(docId, newData);
      setDocument({ ...document, ...newData });
      dispatch(updateClientCaseData(newData));
      // setLoading(false);

      if (!disableToast) {
        ToastManager.getInstance().showToast(
          collectionPath,
          "success",
          "updated"
        );
      }
    } catch (err: any) {
      ToastManager.getInstance().showToast(
        collectionPath,
        "warning",
        "updated"
      );
      setError(err);
    }
  };

  const deleteDocument = async (doc = docId) => {
    try {
      await firestoreWrapper.delete(doc);
      setDocument(null);
    } catch (err: any) {
      setError(err);
    }
  };

  const deleteDocumentFields = async (fields: string[]) => {
    try {
      const result = await firestoreWrapper.deleteFields(docId, fields);
      setDocument(null);
      return result;
    } catch (err: any) {
      setError(err);
      return err;
    }
  };

  // TODO
  const deleteMultipleDocuments = async (paths: []) => {
    // const promises = paths.map((string) => {
    //   return
    // }))
  };

  return {
    document,
    liveDocument,
    isFetchCalled,
    loading,
    error,
    fetchDocument,
    createDocument,
    updateDocument,
    deleteDocument,
    deleteDocumentFields,
    listenAndFetch
  };
}

export default useFirestoreDocument;
