import { Box, Button, Flex, Heading, Select, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { Dropdown } from "../components/common/Dropdown";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { ensureSuperclassFormat } from "../helpers/string_helpers";
import { documentSelectors } from "../redux/documents/selectors";
import { DocumentsSlider } from "./DocumentSlider";
import { LoadingSpinner } from "../components/common/LoadingSpinner";

const DELAY_IN_MS = 500;

export const SummariesPage = () => {
  const { superClass, id: uid, visaType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const superGroupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const navigate = useNavigate();
  const [fakeLoading, setFakeLoading] = useState(false);
  const [selectedSuperClass, setSelectedSuperClass] = useState(superClass);

  const groupedDocuments = superGroupedDocuments.find(
    (group) => group.super_class === superClass
  )?.groups;

  if (!groupedDocuments) return <p>Fetching documents.. </p>;

  const categoryFromPath = searchParams.get("category");

  const categoryFromPathIndex = groupedDocuments.findIndex(
    (group) => group.type === categoryFromPath
  );

  const defaultCategoryIndex =
    categoryFromPathIndex !== -1 ? categoryFromPathIndex : 0;

  const superClassesMap = superGroupedDocuments
    .filter((e) => e.groups.length > 0)
    .map((e) => ({
      original: e.super_class,
      label: ensureSuperclassFormat(e.super_class)
    }));

  const superClasses = superClassesMap.map((e) => e.label);

  useEffect(() => {
    const delayedTimeout = setTimeout(() => {
      // This is here to prevent the double load that happens when we
      // set the selectedSuperClass from the route parameter
      if (superClass === selectedSuperClass) {
        return;
      }
      const path = `/individual/${uid}/${visaType}/documents/${selectedSuperClass}/extracted-information`;
      navigate(path);
      setFakeLoading(false);
    }, DELAY_IN_MS);

    return () => clearTimeout(delayedTimeout);
  }, [selectedSuperClass]);

  return (
    <SectionWrapper
      backTargetPage="Documents"
      bg="white"
      withIndividualEditBox
      borderTopRadius={8}
      onClick={() => {
        const path = `/individual/${uid}/${visaType}/documents`;
        navigate(path);
      }}
      position="relative"
    >
      <Box
        py={2}
        pl={8}
        mb={4}
        bg="accent.lapis"
        color="white"
        borderTopRadius={8}
        position="absolute"
        top="0"
        left={0}
        right={0}
      >
        <Dropdown
          withSearch={false}
          variant="clearButton"
          list={superClasses}
          onItemSelect={(selectedSuperClass) => {
            const superClassOriginal = superClassesMap.find(
              (e) => e.label === selectedSuperClass
            );
            setFakeLoading(true);
            setSelectedSuperClass(superClassOriginal?.original);
          }}
          selectedValue={ensureSuperclassFormat(selectedSuperClass!)}
          title=""
        />
      </Box>

      {fakeLoading && (
        <div className="py-8">
          <LoadingSpinner />
        </div>
      )}

      {!fakeLoading && (
        <Box pl={4} mt={12}>
          {groupedDocuments?.length === 0 ? (
            <Flex direction="column" align="center" justify="center" h="50%">
              <Heading size="md" mb={2}>
                No Documents Available
              </Heading>
              <Text textAlign="center" fontSize="sm">
                It looks like there are no {superClass} documents uploaded at
                the moment.
                <br />
                Please upload documents to start reviewing and extracting
                information.
              </Text>
            </Flex>
          ) : (
            <Box padding={0} className="flex flex-col gap-10" p={4}>
              <div className="inline-flex  items-center">
                <Text mr={4}>Category:</Text>
                <Select
                  variant="cyan"
                  onChange={(e) => {
                    const indx = Number(e.target.value);
                    const categorySelect = groupedDocuments[indx].type;
                    searchParams.set("category", categorySelect);
                    setSearchParams(searchParams);
                  }}
                  value={defaultCategoryIndex}
                  width="fit-content"
                >
                  {groupedDocuments?.map((group, indx) => (
                    <option key={indx} value={indx}>
                      {group.type}
                    </option>
                  ))}
                </Select>
              </div>
              {defaultCategoryIndex !== undefined && (
                <DocumentsSlider
                  categoryIndex={defaultCategoryIndex}
                  documents={
                    groupedDocuments[defaultCategoryIndex]?.subrows ?? []
                  }
                />
              )}
            </Box>
          )}
        </Box>
      )}
    </SectionWrapper>
  );
};
