import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { AddIcon, ChevronDownIcon, DragHandleIcon } from "@chakra-ui/icons";
import { ReactNode, useEffect, useState } from "react";

/**
 * DnD-kit imports
 */
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { getShortString } from "../../helpers/string_helpers";

/**
 * ---------------------------------- Types ----------------------------------
 */
type BaseProps<T> = {
  label?: string;
  withArrow?: boolean;
  list: T[];
  isDisabled?: boolean;
  withToolTip?: boolean;
  withFilter?: boolean;
  /**
   * The field you want to search/filter on.
   * If T is just a string array, pass `searchField={undefined}`
   */
  searchField?: keyof T;
  /**
   * The field used for uniqueness. Typically some `id` or `slug` in T.
   * If T is a string array, pass `mergingField={undefined}`
   */
  mergingField?: keyof T;
  /**
   * Field to group items. E.g., `status` or `category` in T.
   */
  groupBy?: keyof T;
  /**
   * The items that should initially be "checked".
   * You can pass their IDs or strings.
   */
  checkedItems?: string[] | null | undefined;
  disabledItems?: string[] | null | undefined;
  withPlusIcon?: boolean;
  width?: number;
};

type MultiSelectProps<T> = BaseProps<T> & {
  singleSelect: false;
  getSelectedItems: (items: T[]) => void;
  withCheck?: boolean;
  withView?: boolean;
  withShuffle?: boolean;
};

type SingleSelectProps<T> = BaseProps<T> & {
  singleSelect: true;
  getSelectedItem: (item: T) => void;
  withCheck?: never;
  withView?: never;
};

type Props<T> = MultiSelectProps<T> | SingleSelectProps<T>;

type CheckedItem<T> = {
  /**
   * This is the displayed text
   */
  value: string;
  /**
   * This is used as the unique DnD/sortable ID
   */
  id: string;
  disabled?: boolean;
  /**
   * Whether or not the item is currently checked
   */
  checked: boolean;
} & T;

/**
 * ------------------------------ Sortable Tag -------------------------------
 */
function SortableTag<T>({
  item,
  index,
  onClose,
  children
}: {
  item: CheckedItem<T>;
  index: number;
  onClose: (item: CheckedItem<T>) => void;
  children: ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <Box ref={setNodeRef} style={style} display="flex" alignItems="center">
      {/* 1. Drag icon */}
      <Box
        cursor="grab"
        display="flex"
        alignItems="center"
        pr="8px"
        {...attributes}
        {...listeners}
      >
        <DragHandleIcon />
      </Box>

      <Flex
        w="22px"
        textAlign="right"
        mr="8px"
        justifyContent="start"
        alignItems="start"
        fontSize={16}
        fontWeight={500}
      >
        {index + 1}.
      </Flex>

      {/* 3. The Tag with the close button */}
      {children}
    </Box>
  );
}

/**
 * --------------------------- Dropdown Component ----------------------------
 */
export const DropdownGeneric = <T,>({
  label,
  singleSelect,
  list,
  isDisabled,
  withToolTip = false,
  withFilter = true,
  withArrow = false,
  searchField,
  mergingField,
  groupBy,
  checkedItems,
  disabledItems,
  withPlusIcon = false,
  width,
  ...rest
}: Props<T>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState<CheckedItem<T>>();

  const [derivedList, setDerivedList] = useState<CheckedItem<T>[]>(
    list.map<CheckedItem<T>>((element, i) => ({
      ...element,
      checked: checkedItems?.includes(getUniqueId(element)) || false,
      disabled: disabledItems?.includes(getUniqueId(element)) || false,
      value: getDisplayValue(element),
      id: getUniqueId(element),
      stableIndex: i + 1
    }))
  );

  function getUniqueId(item: T): string {
    if (typeof item === "string") return item; // fallback if array of strings
    if (mergingField) return String(item[mergingField]);
    return "";
  }

  function getDisplayValue(item: T): string {
    if (typeof item === "string") return item; // fallback if array of strings
    if (searchField) return String(item[searchField]);
    return "";
  }

  // DnD Sensors
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  // Called after drag is dropped
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = derivedList.findIndex((el) => el.id === active.id);
    const newIndex = derivedList.findIndex((el) => el.id === over.id);
    if (oldIndex < 0 || newIndex < 0) return;

    // Reorder array
    const newList = arrayMove(derivedList, oldIndex, newIndex);
    setDerivedList(newList);
  };

  // Single or Multi select
  const handleSelect = (item: CheckedItem<T>) => {
    if (item.disabled) return;
    if (singleSelect) {
      setSelectedItem(item);
      setMenuIsOpen(false);
    } else {
      const updatedList = derivedList.map((_item) =>
        _item.id === item.id ? { ..._item, checked: !_item.checked } : _item
      );
      setDerivedList(updatedList);
    }
  };

  // For multi-select usage:
  //   1) notify parent of any changes in checked items
  useEffect(() => {
    if (!singleSelect && "getSelectedItems" in rest) {
      const onlyChecked = derivedList.filter((item) => item.checked);
      // Send back the original T objects
      rest.getSelectedItems(onlyChecked as T[]);
    }
  }, [derivedList]);

  // For single-select usage:
  //   1) notify parent once selectedItem changes
  useEffect(() => {
    if (singleSelect && "getSelectedItem" in rest && selectedItem) {
      rest.getSelectedItem(selectedItem);
    }
  }, [selectedItem]);

  // Filter the list (only for displayed search results)
  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  // Group them if needed
  const groupItems = (items: CheckedItem<T>[]) => {
    if (!groupBy) return { "": items };
    return items.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const key = String(item[groupBy]) || "Uncategorized";
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
  };
  const groupedItems = groupItems(filteredList);

  // For search input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <Flex flexDirection="column" gap={4} flexGrow="1" flexShrink="1">
      <Box mb={2} display="flex">
        <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
          <Flex flexDirection="column" flexGrow="1" flexShrink="1">
            <MenuButton
              bg="white"
              mr={4}
              as={Button}
              isDisabled={isDisabled}
              leftIcon={withPlusIcon ? <AddIcon /> : undefined}
              variant="add"
              borderRadius="md"
              width={width}
              onClick={() => setMenuIsOpen((prev) => !prev)}
              rightIcon={withArrow ? <ChevronDownIcon /> : undefined}
            >
              {singleSelect && selectedItem
                ? selectedItem.value
                : label || "Select"}
            </MenuButton>
            <MenuList
              width="30vw"
              maxHeight="50vh"
              overflow="auto"
              display="flex"
              flexDirection="column"
              sx={{
                "&::-webkit-scrollbar": { width: "10px" },
                "&::-webkit-scrollbar-track": {
                  background: "primary.lightGray"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "accent.lapis",
                  borderRadius: "8px"
                }
              }}
            >
              {withFilter && (
                <Box px={2} mb={2}>
                  <Input
                    placeholder="Search..."
                    variant="filled"
                    bg={useColorModeValue(
                      "background.white",
                      "background.darkWhite"
                    )}
                    _focus={{
                      borderColor: useColorModeValue(
                        "primary.focusOutline",
                        "primary.red"
                      )
                    }}
                    onChange={handleChange}
                  />
                </Box>
              )}

              {/* Render grouped items */}
              {Object.keys(groupedItems).map((groupKey) => (
                <Box key={groupKey}>
                  {groupKey && groupKey !== "" && (
                    <Text fontWeight="bold" px={4} py={2} color="black">
                      {groupKey}
                    </Text>
                  )}
                  {groupedItems[groupKey].map((item, index) => (
                    <MenuItem
                      key={`${item.id}-${index}`}
                      closeOnSelect={singleSelect}
                      onClick={() => handleSelect(item)}
                      _hover={{ bg: "primary.focusOutline" }}
                    >
                      {"withCheck" in rest && rest.withCheck ? (
                        <Checkbox
                          pointerEvents="none"
                          isChecked={item.checked}
                          isDisabled={item.disabled}
                        >
                          {withToolTip ? (
                            <Tooltip label={item.value}>
                              {getShortString(item.value)}
                            </Tooltip>
                          ) : (
                            <Text color="black">{item.value}</Text>
                          )}
                        </Checkbox>
                      ) : (
                        <Text color="black">{item.value}</Text>
                      )}
                    </MenuItem>
                  ))}
                </Box>
              ))}
            </MenuList>
          </Flex>
        </Menu>
      </Box>
      {/* if multi-select and without shuffle */}
      {!singleSelect && rest.withView && !rest.withShuffle && (
        <VStack align="stretch" spacing={3} maxH="md" overflowY="auto">
          {derivedList
            .filter((item) => item.checked)
            .map((item, i) => (
              <Flex key={item.id} align="flex-start" width="100%">
                {/* 2) Pass “index” to SortableTag */}
                <Tooltip label={item.value}>
                  <Tag size="lg" variant="brand" maxW="600px" overflow="hidden">
                    <TagLabel>{item.value}</TagLabel>
                    <TagCloseButton
                      onClick={(e) => {
                        e.stopPropagation(); // Don’t trigger drag
                        handleSelect(item);
                      }}
                    />
                  </Tag>
                </Tooltip>
              </Flex>
            ))}
        </VStack>
      )}
      {/* If multi-select with a "view" of the tags  */}
      {!singleSelect && rest.withView && rest.withShuffle && (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <SortableContext
            items={derivedList.filter((i) => i.checked).map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <VStack align="stretch" spacing={3} maxH="md" overflowY="auto">
              {derivedList
                .filter((item) => item.checked)
                .map((item, i) => (
                  <Flex key={item.id} align="flex-start" width="100%">
                    {/* 2) Pass “index” to SortableTag */}
                    <SortableTag item={item} index={i} onClose={handleSelect}>
                      <Tag
                        size="lg"
                        variant="brand"
                        maxW="600px"
                        overflow="hidden"
                      >
                        <TagLabel>{item.value}</TagLabel>
                        <TagCloseButton
                          onClick={(e) => {
                            e.stopPropagation(); // Don’t trigger drag
                            handleSelect(item);
                          }}
                        />
                      </Tag>
                    </SortableTag>
                  </Flex>
                ))}
            </VStack>
          </SortableContext>
        </DndContext>
      )}
    </Flex>
  );
};
