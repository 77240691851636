/* eslint-disable react/button-has-type */
import {
  Search2Icon,
  TriangleDownIcon,
  TriangleUpIcon
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra
} from "@chakra-ui/react";
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import {
  Link as LinkDom,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { useDebounce } from "use-debounce";
import { ClientData, visaTypeToPath } from "../types/tables-data";
import { CaseActions } from "./CaseActions";
import CustomDropdown from "./common/CustomSelectDropDown";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  isSortable?: boolean;
  sortBy?: string;
};

export function DataTable<Data extends ClientData>({
  data,
  columns,
  isSortable = false,
  sortBy
}: DataTableProps<Data>) {
  const params = useParams();
  const [sorting, setSorting] = useState<SortingState>([
    { id: sortBy ?? "createdDate", desc: true }
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: Number(localStorage.getItem("dataTablePageSize")) || 10
  });
  const navigate = useNavigate();
  const location = useLocation();

  // Use this state to hide the table temporary to optimize performance
  const [filteringData, setFilteringData] = useState(false);

  // This will run the inner globalFilter
  const [filter, setFilter] = useState("");

  const [filterInput, setFilterInput] = useState("");

  const [debouncedFilter] = useDebounce(filterInput, 300);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      ...(isSortable && { sorting }),
      globalFilter: filter,
      pagination,
      columnVisibility: {
        email: false,
        id: false
      }
    },

    onGlobalFilterChange: setFilter
  });

  useEffect(() => {
    setFilter(debouncedFilter);
    setFilteringData(false);
  }, [debouncedFilter]);

  // Reset sorting and filter when navigating back from a detail page
  useEffect(() => {
    if (location.state?.from === "/cases") {
      setSorting([]);
      setFilter("");
    }
  }, [location.state]);

  return (
    <Box>
      <div className="flex items-center gap-2 ">
        <InputGroup borderRadius="16px" maxW="50%">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="primary.blue" />
          </InputLeftElement>
          <Input
            bg="white"
            type="search"
            placeholder="Search..."
            value={filterInput}
            onChange={(e) => {
              setFilterInput(e.target.value);
              setFilteringData(true);
            }}
          />
        </InputGroup>
        <Spacer />
        <Text size="sm">Viewing {data.length} cases</Text>
        <LinkDom to="/new-case">
          <Button variant="primaryFilled" my="20px">
            Add new case
          </Button>
        </LinkDom>
      </div>
      <Box className=" overflow-x-auto overflow-y-scroll max-h-[60vh]">
        <Table variant="stripedHover">
          <Thead className="bg-white" style={{ borderRadius: "20px" }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { meta } = header.column.columnDef;
                  return (
                    <Th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      isNumeric={meta?.isNumeric}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <chakra.span
                        pl={header.column.getIsSorted() ? "1px" : "0"}
                      >
                        {header.column.getIsSorted() &&
                          (header.column.getIsSorted() === "desc" ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          ))}
                      </chakra.span>
                    </Th>
                  );
                })}
                <Th />
              </Tr>
            ))}
          </Thead>

          {filteringData && (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  color="primary.blue"
                  size="xl"
                />
              </Td>
            </Tr>
          )}
          {!filteringData && (
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  _hover={{ bg: "primary.backgroundLight" }}
                  key={row.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!params.caseID && location.pathname === "/cases") {
                      const visaPath = row.original.visa
                        ? visaTypeToPath[row.original.visa] || row.original.visa
                        : "";
                      const path = visaPath
                        ? `/individual/${row.original.id}/${visaPath}/documents/`
                        : `/individual/${row.original.id}`;
                      // Ensure we're setting the state to track where we navigated from
                      navigate(path, { state: { from: "/cases" } });
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const { meta, id } = cell.column.columnDef;
                    return (
                      <Td
                        key={cell.id}
                        isNumeric={meta?.isNumeric}
                        whiteSpace={id === "visa" ? "nowrap" : "unset"}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                  <Td key="delete" textAlign="center">
                    <CaseActions
                      caseId={row.original.id}
                      visaType={
                        row.original.visa
                          ? visaTypeToPath[row.original.visa]
                          : row.original.visa || ""
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </Box>
      <Box>
        <div className="flex items-center gap-2 mt-2">
          <Spacer />

          <Text>Cases per page</Text>
          <Box
            sx={{
              input: {
                bg: "white",
                _hover: { bg: "gray.200" }
              },
              button: {
                bg: "white",
                _hover: { bg: "gray.200" }
              }
            }}
          >
            <CustomDropdown
              options={[10, 20, 50, 75, 100]}
              casesNumber={data.length}
              currentValue={table.getState().pagination.pageSize}
              onChange={(val: any) => {
                const newPageSize = Number(val);
                table.setPageSize(newPageSize);
                localStorage.setItem("dataTablePageSize", String(newPageSize));
              }}
            />
          </Box>

          <div className="flex items-center gap-2 my-4">
            <Button
              aria-label="previous page"
              variant="outlineIconButton"
              leftIcon={<FaChevronLeft />}
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <span className="flex items-end gap-2">
              {/* First Page */}
              <Button
                variant={
                  table.getState().pagination.pageIndex === 0
                    ? "primaryFilled"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(0)}
                px={0}
                py={0}
                className="rounded-full"
              >
                1
              </Button>

              {table.getState().pagination.pageIndex > 1 && (
                <Button
                  pointerEvents="none"
                  variant="ghost"
                  px={0}
                  py={0}
                  className="rounded-full"
                >
                  ...
                </Button>
              )}

              {/* Current Page */}
              {table.getState().pagination.pageIndex > 0 &&
                table.getState().pagination.pageIndex <
                  table.getPageCount() - 1 && (
                  <Button
                    variant="primaryFilled"
                    px={0}
                    py={0}
                    className="rounded-full"
                  >
                    {table.getState().pagination.pageIndex + 1}
                  </Button>
                )}

              {table.getState().pagination.pageIndex <
                table.getPageCount() - 2 && (
                <Button
                  pointerEvents="none"
                  variant="ghost"
                  onClick={() => table.setPageIndex(0)}
                  px={0}
                  py={0}
                  className="rounded-full"
                >
                  ...
                </Button>
              )}

              {/* Last Page */}
              <Button
                variant={
                  table.getState().pagination.pageIndex ===
                  table.getPageCount() - 1
                    ? "primaryFilled"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                px={0}
                py={0}
                className="rounded-full"
              >
                {table.getPageCount()}
              </Button>
            </span>
            <Button
              aria-label="next page"
              variant="outlineIconButton"
              rightIcon={<FaChevronRight />}
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </Box>
    </Box>
  );
}
