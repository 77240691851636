import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  UnorderedList,
  useDisclosure
} from "@chakra-ui/react";
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkDom, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { db } from "../api/firebaseApi";
import images from "../assets/image-export-assets";
import AllStatesSelect from "../components/common/AllStatesSelect";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { useAddFileToDB } from "../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../hooks/useDeleteFileFromDB";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import {
  updateLawyerAvatar,
  updateLawyerData,
  updateLawyerEmail
} from "../redux/lawyer/lawyerSlice";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { DATABASE } from "../types/tables-data";

import { supportedExtensionsString } from "../constans";
import { MFAManagement } from "../components/MFAManagement";

const passPlaceholder = "*********************";

export const LawyerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateMailError, setIsUpdateMailError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMailMessage, setErrorMailMessage] = useState("");
  const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;

  const {
    firstName,
    middleName,
    lastName,
    title,
    email,
    address,
    state,
    city,
    zipcode,
    phone,
    website,
    avatarSrc,
    avatarName,
    lawFirm
  } = useSelector(lawyerSelectors.selectAll);

  const uid = useSelector(lawyerSelectors.selectUid);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitDeleteFile } = useDeleteFileFromDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();

  const handleUpdateEmail = async (value: string) => {
    if (value !== email && user) {
      setIsLoading(true);
      try {
        await updateEmail(user, `${value}`);
        dispatch(updateLawyerEmail(value));
        navigate("/cases");
      } catch (e: any) {
        const errorCode = e.code;
        if (errorCode === "auth/requires-recent-login") {
          setErrorMailMessage("Need recent login");
        }
        setIsUpdateMailError(true);
        setShowErrorMessage(true);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleUpdatePassword = async (value: string) => {
    if (value !== passPlaceholder && user) {
      setIsLoading(true);
      try {
        await updatePassword(user, `${value}`);
        navigate("/cases");
      } catch (e: any) {
        const errorCode = e.code;
        if (errorCode === "auth/requires-recent-login") {
          setErrorPasswordMessage("Need recent login");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUpdateData = async (values: Record<string, string>) => {
    setShowErrorMessage(false);
    setIsLoading(true);
    try {
      const lawyerRef = doc(db, DATABASE.LAWYERS, `${uid}`);

      await setDoc(
        lawyerRef,
        {
          firstName: values.firstName,
          middleName: values.middleName ?? "",
          lastName: values.lastName,
          title: values.title ?? "",
          address: values.address ?? "",
          city: values.city ?? "",
          state: values.state ?? "",
          zipcode: values.zipcode ?? "",
          phone: values.phone ?? "",
          lawFirm: values.lawFirm ?? ""
        },
        { merge: true }
      );
      dispatch(
        updateLawyerData({
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          lawFirm: values.lawFirm ?? "",
          title: values.title ?? "",
          address: values.address,
          state: values.state,
          city: values.city,
          phone: values.phone,
          zipcode: values.zipcode
        })
      );
    } catch (e) {
      console.error("Error is:", e);
    } finally {
      setIsLoading(false);
      if (values.email !== email) {
        handleUpdateEmail(values.email);
      }
      if (values.password !== passPlaceholder) {
        handleUpdatePassword(values.password);
      }
      if (values.email === email && values.password === passPlaceholder) {
        navigate("/cases");
      }
      onClose();
    }
  };

  YupPassword(Yup);

  const formik = useFormik({
    initialValues: {
      firstName,
      middleName,
      lastName,
      title,
      email,
      password: passPlaceholder,
      address,
      state,
      city,
      zipcode,
      phone,
      webSite: website,
      lawFirm
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .minLowercase(1, "Password must contain at least 1 lower case letter")
        .minUppercase(1, "Password must contain at least 1 upper case letter")
        .minNumbers(1, "Password must contain at least 1 number")
        .minSymbols(1, "Password must contain at least 1 special character")
        .required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      handleUpdateData(values);
    }
  });

  useEffect(() => {
    if (isUpdateMailError) {
      formik.setFieldValue("email", email, false);
      setIsUpdateMailError(false);
    }
  }, [isUpdateMailError]);
  const changedLawyerData = useMemo(() => {
    const changedValues: string[] = [];
    if (formik.values.firstName !== firstName) {
      changedValues.push("First name");
    }

    if (formik.values.middleName !== middleName) {
      changedValues.push("Middle name");
    }
    if (formik.values.lastName !== lastName) {
      changedValues.push("Last name");
    }
    if (formik.values.title !== title) {
      changedValues.push("Title");
    }
    if (formik.values.lawFirm !== lawFirm) {
      changedValues.push("Law Firm Name");
    }
    if (formik.values.email !== email) {
      changedValues.push("Email");
    }
    if (formik.values.password !== passPlaceholder) {
      changedValues.push("Password");
    }
    if (formik.values.address !== address) {
      changedValues.push("Address");
    }
    if (formik.values.zipcode !== zipcode) {
      changedValues.push("Zipcode");
    }
    if (formik.values.phone !== phone) {
      changedValues.push("Phone");
    }
    if (formik.values.city !== city) {
      changedValues.push("City");
    }
    if (formik.values.state !== state) {
      changedValues.push("State");
    }
    if (formik.values.webSite !== website) {
      changedValues.push("Website");
    }
    return changedValues;
  }, [formik.values]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const getImagesFromBD = async () => {
    try {
      await onSubmitGetFiles(`${DATABASE.LAWYERS}/avatars/${uid}/`);
    } catch (e) {
      console.log(e);
      dispatch(updateLawyerAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  };

  useEffect(() => {
    if (storageFiles.length) {
      storageFiles.forEach((item) => {
        getDownloadURL(item).then((url) => {
          dispatch(
            updateLawyerAvatar({ avatarSrc: url, avatarName: item.name })
          );
        });
      });
    }
  }, [storageFiles]);

  const uploadImage = async () => {
    if (selectedFile) {
      try {
        await onSubmitAddFile(
          selectedFile,
          `${DATABASE.LAWYERS}/avatars/${uid}/${selectedFile.name}`,
          true
        );
        setSelectedFile(null);
        getImagesFromBD();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteImage = async () => {
    if (avatarName) {
      try {
        await onSubmitDeleteFile(
          `${DATABASE.LAWYERS}/avatars/${uid}/${avatarName}`
        );
        dispatch(updateLawyerAvatar({ avatarSrc: "/", avatarName: "" }));
      } catch (e) {
        console.log(e);
      } finally {
        uploadImage();
      }
    }
  };

  useEffect(() => {
    if (selectedFile && avatarName) {
      deleteImage();
    } else if (selectedFile) {
      uploadImage();
    }
  }, [selectedFile]);

  return (
    <Container maxW="container.xl" p={4}>
      <Box 
        bg="white" 
        borderRadius="lg" 
        p={6} 
        shadow="sm"
        border="1px"
        borderColor="gray.200"
      >
        <Box className="w-full">
          <div className="relative mb-6 w-full h-28 object-fill ">
            <Flex flexDirection="column" alignItems="center">
              <Avatar
                name={`${formik.values.firstName} ${formik.values.lastName}`}
                src={avatarSrc}
                width="6rem"
                height="6rem"
                cursor="pointer"
                onClick={() => {
                  fileRef.current?.click();
                }}
                className=""
              />
              <Text color="primary.blue" className="font-semibold text-xl">
                {firstName} {lastName}
              </Text>
              <Text className="text-xs">Account type: Lawyer</Text>
            </Flex>
          </div>

          <Input
            type="file"
            ref={fileRef}
            onChange={(e) => {
              if (e.target.files) {
                setSelectedFile(e.target.files[0]);
              }
            }}
            accept={supportedExtensionsString}
            opacity="0"
            h="0"
            w="0"
            lineHeight="0"
            overflow="hidden"
            p="0"
            m="0"
          />
          <Divider my={8} />
          <Box className="justify-start text-left mb-8">
            <Text className="text-md text-[darkBlue] font-semibold">
              Account Settings
            </Text>
            <Text className="text-xs" color="">
              Here you can change your account information
            </Text>
          </Box>
          <div className="flex gap-2">
            <FormControl
              my="20px"
              variant="floating"
              id="firstName"
              isRequired
              isInvalid={formik.touched.firstName && !!formik.errors.firstName}
            >
              <Input
                placeholder=" "
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>First name</FormLabel>
              <FormErrorMessage>Your First name is required</FormErrorMessage>
            </FormControl>

            <FormControl
              my="20px"
              variant="floating"
              id="middleName"
              isInvalid={
                formik.touched.middleName && !!formik.errors.middleName
              }
            >
              <Input
                placeholder=" "
                name="middleName"
                type="text"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>Middle name</FormLabel>
            </FormControl>

            <FormControl
              my="20px"
              variant="floating"
              id="lastName"
              isRequired
              isInvalid={formik.touched.lastName && !!formik.errors.lastName}
            >
              <Input
                placeholder=" "
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>Last name</FormLabel>
              <FormErrorMessage>Your Last name is required</FormErrorMessage>
            </FormControl>
          </div>
          <div className="flex gap-2 mb-[20px]">
            <FormControl variant="fixed">
              <FormLabel>Title</FormLabel>

              <Select
                placeholder="Select Title"
                onChange={formik.handleChange}
                name="title"
                id="title"
                value={formik.values.title}
              >
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Dr.">Dr.</option>
              </Select>
            </FormControl>
            <FormControl variant="fixed">
              <FormLabel>Law Firm Name</FormLabel>
              <Input
                id="lawFirm"
                name="lawFirm"
                type="text"
                value={formik.values.lawFirm}
                onChange={formik.handleChange}
              />
            </FormControl>
          </div>
          <Divider mb={4} />
          <div className="flex gap-2">
            <FormControl my="20px" variant="floating" id="address">
              <Input
                placeholder=" "
                name="address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>Address</FormLabel>
            </FormControl>
            <FormControl my="20px" variant="floating" id="city">
              <Input
                placeholder=" "
                name="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>City</FormLabel>
            </FormControl>
          </div>
          <div className="flex gap-2 mb-[20px]">
            <FormControl variant="fixed" id="state">
              <FormLabel>State</FormLabel>
              <AllStatesSelect
                value={formik.values.state}
                handleChange={formik.handleChange}
              />
            </FormControl>
            <FormControl variant="fixed" id="zipcode">
              <FormLabel>Zip Code</FormLabel>
              <Input
                placeholder=" "
                name="zipcode"
                type="text"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </FormControl>
          </div>
          <div className="flex gap-2 ">
            <FormControl
              my="20px"
              variant="floating"
              id="phone"
              isInvalid={formik.touched.phone && !!formik.errors.phone}
            >
              <Input
                placeholder=" "
                name="phone"
                type="text"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormLabel>Phone</FormLabel>
            </FormControl>
          </div>

          <div className="flex gap-2">
            <FormControl
              my="20px"
              variant="floating"
              id="email"
              isRequired
              isInvalid={formik.touched.email && !!formik.errors.email}
            >
              <Input
                placeholder=" "
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-email"
              />
              <FormLabel>Email</FormLabel>
              <FormErrorMessage>Your Email is invalid</FormErrorMessage>
              {showErrorMessage && (
                <Box color="red.500" fontSize="sm">
                  {errorMailMessage || "This email is already used!"}
                </Box>
              )}
            </FormControl>

            <FormControl
              my="20px"
              variant="floating"
              id="password"
              isInvalid={
                formik.touched.password &&
                !!formik.errors.password &&
                formik.values.password !== passPlaceholder
              }
              isRequired
            >
              <Input
                placeholder=" "
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-password"
              />
              <FormLabel>Password</FormLabel>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              {showErrorMessage && (
                <Box color="red.500" fontSize="sm">
                  {errorPasswordMessage}
                </Box>
              )}
            </FormControl>
          </div>
        </Box>
        <Flex justifyContent="center" alignItems="center" gap="12px">
          <LinkDom to="/cases">
            <Button variant="secondaryOutline" type="button">
              Cancel
            </Button>
          </LinkDom>
          <Button
            variant="primaryFilled"
            type="submit"
            isLoading={isLoading}
            onClick={() => {
              if (changedLawyerData.length) {
                onOpen();
              } else {
                navigate("/cases");
              }
            }}
          >
            Save
          </Button>
        </Flex>
      </Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            You changed the following fields:{" "}
            <UnorderedList my="15px">
              {changedLawyerData.map((data, i) => (
                <ListItem key={i}>{data}</ListItem>
              ))}
            </UnorderedList>
            <br />
            <strong>Would you like to save it?</strong>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primaryFilled"
              ml={3}
              onClick={() => handleUpdateData(formik.values)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box 
        mt={6}
        bg="white" 
        borderRadius="lg" 
        p={6} 
        shadow="sm"
        border="1px"
        borderColor="gray.200"
      >
        <Box className="w-full">
          <Box className="justify-start text-left mb-6">
            <Text className="text-md text-[darkBlue] font-semibold">
              Two-Factor Authentication
            </Text>
            <Text className="text-xs">
              Add an extra layer of security to your account
            </Text>
          </Box>
          <MFAManagement />
        </Box>
      </Box>
    </Container>
  );
};
