import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  Unsubscribe,
  where
} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { GroupedDocument, SuperGroupedDocument } from "../helpers/helpers";
import {
  addNewDocument,
  deleteDocument,
  setGroupedDocuments as setSuperGroupedDocumentsSlice,
  updateDocument
} from "../redux/documents/documentSlice";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "../redux/documents/documentsThunk";
import { VisaDocumentType } from "../redux/documents/types";
import { store, useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";

type PathWithType = {
  path: string;
  super_class: string;
  documentType: VisaDocumentType;
};
type DocumentIdWithPath = {
  path: string;
  id: string;
};
type FlattenedGroup = {
  category: string;
  documentIds: DocumentIdWithPath[];
  test: string[];
};
type FlattenSuperGroupedDocument = {
  super_class: string;
  groups: FlattenedGroup[];
};

const useDocuments = (paths: PathWithType[], refetch?: boolean) => {
  const pendingDocumentIds = useRef<Set<string>>(new Set());
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const { id, visaType } = useParams();
  const dispatch = useTypedDispatch();

  const [groupedDocuments, setGroupedDocuments] = useState<
    GroupedDocument[] | null
  >(null);

  const [superGroupedDocuments, setSuperGroupedDocuments] = useState<
    SuperGroupedDocument[] | null
  >(null);

  // 2. Group documents by `super_class` and sort
  const fetchOrderFromFirestore = useCallback(async () => {
    if (!id) return [];
    const documentPath = `/documents/${id}/`;
    const orderDocumentReference = doc(db, documentPath);
    const document = await getDoc(orderDocumentReference);
    return document.exists()
      ? (document.data() as any)?.documentsTableOrder
      : [];
  }, []);

  const flattenSuperGroupedDocument = (
    _superGroupedDocuments: SuperGroupedDocument[]
  ) => {
    return _superGroupedDocuments.map((superGrouped) => ({
      super_class: superGrouped.super_class,
      groups: superGrouped.groups.map((group) => ({
        category: group.type,
        documentIds: group.subrows.map((doc) => ({
          id: doc.id,
          path: doc.collectionPath || `/documents/${id}/evidence_docs`
        }))
      }))
    }));
  };

  const updateCurrentOrder = useCallback(
    (_superGroupedDocuments: SuperGroupedDocument[]) => {
      if (!id || !_superGroupedDocuments) return;

      const docRef = doc(db, `/documents/${id}`);

      setDoc(docRef, {
        documentsTableOrder: flattenSuperGroupedDocument(_superGroupedDocuments)
      });
      // Preserve and dispatch updated grouped documents
      const updatedDocuments = _superGroupedDocuments.map((superGroup) => ({
        ...superGroup,
        groups: superGroup.groups.map((group) => ({
          ...group,
          subrows: group.subrows.map((subrow) => ({
            ...subrow,
            docUrl: subrow.docUrl // Ensure this stays intact
          }))
        }))
      }));

      dispatch(setSuperGroupedDocumentsSlice(updatedDocuments));
    },
    []
  );

  const groupDocumentsBySuperClass = (documents: DataDocs[]) => {
    const SUPER_GROUPED_MAP: Record<string, SuperGroupedDocument> = {
      Processing: { groups: [], super_class: "Processing" },
      Standard: { groups: [], super_class: "Standard" },
      Evidence: { groups: [], super_class: "Evidence" },
      "Expert Documents": { groups: [], super_class: "Expert Documents" },
      "Law Firm Intake": { groups: [], super_class: "Law Firm Intake" },
      Unclassified: { groups: [], super_class: "Unclassified" }
    };

    documents.forEach((doc) => {
      const { super_class: superClass, criterion } = doc;
      if (!superClass || !criterion) return;

      if (!SUPER_GROUPED_MAP[superClass]) {
        SUPER_GROUPED_MAP[superClass] = {
          super_class: superClass,
          groups: []
        };
      }

      const superGroup = SUPER_GROUPED_MAP[superClass];

      let group = superGroup.groups.find((g) => g.type === criterion);
      if (!group) {
        group = { type: criterion, subrows: [], index: -1, expanded: false };
        superGroup.groups.push(group);
      }

      group.subrows.push(doc);
    });

    return Object.values(SUPER_GROUPED_MAP);
  };

  const sortWithOrder = useCallback(
    (
      superGroupedDocs: SuperGroupedDocument[],
      order: FlattenSuperGroupedDocument[]
    ) => {
      if (!order?.length || !superGroupedDocs?.length) return superGroupedDocs;

      const superClassOrderMap: Record<string, number> = {};
      const groupOrderMap: Record<string, number> = {};
      const subrowOrderMap: Record<string, Record<string, number>> = {};

      order.forEach((superGroup, superIndex) => {
        superClassOrderMap[superGroup.super_class] = superIndex;
        superGroup.groups.forEach((group, groupIndex) => {
          const uniqueKey = `${superGroup.super_class}:${group.category}`;
          groupOrderMap[uniqueKey] = groupIndex;
          subrowOrderMap[uniqueKey] = group.documentIds.reduce(
            (map, id, index) => ({ ...map, [id.id]: index }),
            {}
          );
        });
      });

      return [...superGroupedDocs].map((superGroup) => ({
        ...superGroup,
        groups: [...superGroup.groups]
          .sort((a, b) => {
            const keyA = `${superGroup.super_class}:${a.type}`;
            const keyB = `${superGroup.super_class}:${b.type}`;
            return (
              (groupOrderMap[keyA] ?? Infinity) -
              (groupOrderMap[keyB] ?? Infinity)
            );
          })
          .map((group) => {
            const uniqueKey = `${superGroup.super_class}:${group.type}`;
            return {
              ...group,
              subrows: [...group.subrows].sort(
                (a, b) =>
                  (subrowOrderMap[uniqueKey]?.[a.id!] ?? Infinity) -
                  (subrowOrderMap[uniqueKey]?.[b.id!] ?? Infinity)
              )
            };
          })
      }));
    },
    []
  );

  useEffect(() => {
    const unsubscribeList: Unsubscribe[] = [];
    const fetchedDocumentIds = new Set<string>();

    const fetchAndListen = async () => {
      const documentPromises = paths.map((path) => {
        const collectionRef = collection(db, path.path);
        const filteredQuery = query(
          collectionRef,
          where("isDeleted", "==", false)
        );

        return getDocs(filteredQuery).then((querySnapshot) => {
          const initialDocuments = querySnapshot.docs.map((doc) => {
            const uploadedDate = doc.data().uploadDate;

            // Backward compatibility check with older formats
            const uploadDateInMillis =
              uploadedDate instanceof Timestamp
                ? uploadedDate.toMillis()
                : typeof uploadedDate === "number"
                ? uploadedDate
                : null;
              
            const isNew =
              uploadDateInMillis! + (30 * 1000) > Timestamp.now().toMillis() &&
              doc.data().isNew;

            return {
              id: doc.id,
              docRef: doc.ref.path,
              isDeleted: doc.data().isDeleted ?? false,
              super_class:
                doc.data().super_class === null ||
                doc.data().super_class === "" ||
                "Processing",
              criterion: doc.data().criterion || "Processing",
              documentType: path.documentType,
              collectionPath: path.path,
              ...doc.data(),
              isNew
            } as DataDocs;
          }) as DataDocs[];

          // Track fetched document IDs
          initialDocuments.forEach((doc) => fetchedDocumentIds.add(doc.id!));

          return initialDocuments;
        });
      });

      const allDocuments = (await Promise.all(documentPromises)).flat();

      const superGrouped = groupDocumentsBySuperClass(allDocuments);
      let sortedSuperGroupedDocs = superGrouped;
      // TODO : avoid calling fetchOrder every time documents change
      const fetchedOrder = await fetchOrderFromFirestore();

      if (fetchedOrder) {
        sortedSuperGroupedDocs = sortWithOrder(
          sortedSuperGroupedDocs,
          fetchedOrder
        );
      }

      setSuperGroupedDocuments(sortedSuperGroupedDocs);
      dispatch(setSuperGroupedDocumentsSlice(sortedSuperGroupedDocs));
      setIsLoadingDocuments(false);

      dispatch(
        generateDocumentUrls({
          documents: allDocuments.map((doc) => ({
            filePath: doc.filePath!,
            id: doc.id!,
            super_class: doc.super_class ?? "",
            criterion: doc.criterion ?? ""
          }))
        })
      );

      paths.forEach((path) => {
        const collectionRef = collection(db, path.path);
        const filteredQuery = query(
          collectionRef,
          where("isDeleted", "==", false)
        );

        const unsubscribe = onSnapshot(filteredQuery, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const document = {
              ...change.doc.data(),
              docRef: change.doc.ref.path,
              id: change.doc.id,
              super_class: change.doc.data().super_class,
              criterion: change.doc.data().criterion,
              isDeleted: change.doc.data().isDeleted ?? false
            } as DataDocs;

            const superClassDetected =
              document.super_class || document.super_class !== "";
            if (
              change.type === "added" &&
              !fetchedDocumentIds.has(document.id!)
            ) {
              dispatch(addNewDocument(document));
            } else if (change.type === "modified" && !document.isProcessing) {
              if (document.isDeleted === true) {
                dispatch(deleteDocument(document));
              }
              // only execute when the classifier detects the super_class
              if (
                superClassDetected &&
                document.criterion &&
                document.filePath &&
                document.id &&
                document.super_class
              ) {
                dispatch(updateDocument(document));
                const state = store.getState();

                const isUrlAlreadyGenerated =
                  state.document.groupedDocuments?.some(
                    (group) =>
                      group.super_class === document.super_class &&
                      group.groups?.some((subGroup) =>
                        subGroup.subrows?.some(
                          (doc) => doc.id === document.id && doc.docUrl
                        )
                      )
                  );
                if (!isUrlAlreadyGenerated) {
                  if (!pendingDocumentIds.current.has(document.id)) {
                    pendingDocumentIds.current.add(document.id);
                    dispatch(
                      generateSingleDocumentUrl({
                        criterion: document.criterion,
                        filePath: document.filePath,
                        id: document.id,
                        super_class: document.super_class
                      })
                    );
                  }
                }
              }
            } else if (change.type === "removed") {
              dispatch(deleteDocument(document));
            }
          });
        });

        unsubscribeList.push(unsubscribe);
      });
    };

    // Ensure that we only run it one time
    setIsLoadingDocuments(true);

    if (refetch === undefined) fetchAndListen();
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const isDocumentsEmpty = (
    _superGroupedDocuments: SuperGroupedDocument[] | null
  ) => {
    if (!_superGroupedDocuments) return true;
    return _superGroupedDocuments.every(
      (superGroup) => superGroup.groups.length === 0
    );
  };
  return {
    groupedDocuments,
    superGroupedDocuments,
    setGroupedDocuments,
    setSuperGroupedDocuments,
    updateCurrentOrder,
    uid: id,
    isDocumentsEmpty,
    visaType,
    isLoadingDocuments
  };
};

export default useDocuments;
